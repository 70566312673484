<template>
  <div :class="isDashboard ? 'p-0 m-0' : 'container-fluid p-0 m-0'">
    <div :class="isDashboard ? '' : 'row d-flex justify-content-center'" id="taskcontent">
      <div class="col-12 mb-4 px-3" v-if="tasks.length === 0 && !isDashboard">Keine Aufgaben und Infos vorhanden</div>
      <div class="col-auto mb-4 d-flex align-content-stretch" v-for="item in tasks" :key="item.id">
        <div class="card card-height shadow-lg" style="min-width: 18rem; max-width: 18rem">
          <i v-if="item.typ === 4" class="fas fa-circle-info pt-1 card-img-top text-secondary"></i>
          <i v-else-if="item.kategorie === Task.TASK_CAT_HYGIENE" class="fas fa-spray-can-sparkles pt-1 card-img-top text-secondary"></i>
          <i v-else class="fas fa-clipboard pt-1 card-img-top text-secondary"></i>

          <div class="card-body text-center overflow-hidden">
            <div class="card-title" style="">
              <h2 class="mt-2 pr-3 pl-3 font-weight-bold">{{ item.title }}</h2>

              <div v-if="!isCheckin">
                <p v-if="item.betroffen.length > 0">Betrifft: {{ item.betroffen.map(val => val.text).join(", ") }}</p>
                <p v-if="item.orgUnit">Betrifft: {{ item.orgUnit.name }}</p>
              </div>
              <p v-if="item.kfz">
                <list-item-component :dto="item.kfz"></list-item-component>
              </p>
              <p v-if="item.medProdukt">
                <list-item-component :dto="item.medProdukt"></list-item-component>
              </p>
              <p v-if="item.material">
                <list-item-component :dto="item.material"></list-item-component>
              </p>

              <div class="p-0 m-0 text-muted" style="font-size: 0.75rem">
                <!--<div>ID: {{ item.master ? item.master.id : item.id }}</div>-->
                <div class="d-flex justify-content-center" v-if="!isCheckin">{{ item.zustaendig.map(val => val.text).join(", ") }},
                  <p v-if="!isCheckin && item.faelligkeit" class="ml-2 p-0 m-0"
                     :class="{'text-primary': isPastDue(item.faelligkeit), 'text-muted': !isPastDue(item.faelligkeit)}"
                     style="font-size: 0.75rem">
                     Fällig: {{ dateHelper.parseDate(item.faelligkeit) }}
                  </p>
                </div>
              </div>

            </div>

          </div>

          <div class="card-footer border-0 text-center" style="background: white">
            <p class="p-0 m-0">{{ item.status?.name }}</p>
          </div>
          <div class="card-footer text-center" v-if="item.typ !== Task.TASK_TYP_LOGIN">
            <button v-if="item.kategorie !== Task.TASK_CAT_HYGIENE" title="Mehr anzeigen" class="btn btn-secondary mx-1" @click="openModal(item)"><i
                class="fas fa-angle-double-right"></i></button>
            <button v-if="!isCheckin && item.kategorie !== Task.TASK_CAT_HYGIENE" title="Chats" class="btn btn-secondary mx-1" @click="chats(item)"><i
                class="fas fa-comments"></i></button>
            <button title="Notizen" class="btn btn-secondary mx-1" @click="notizen(item)"><i
                class="fas fa-sticky-note"></i></button>
            <button :disabled="!(item.files && item.files.length > 0)" title="Dateien" class="btn btn-secondary mx-1" @click="openFilesModal(item)"><i
                class="fas fa-paperclip"></i></button>
            <button v-if="item.kategorie === Task.TASK_CAT_HYGIENE" title="Hygienemaßnahme erfassen" class="btn btn-success mx-1" @click="processHygiene(item)"><i
                class="fas fa-spray-can-sparkles"></i></button>
            <button v-if="item.kategorie !== Task.TASK_CAT_HYGIENE" title="Abarbeiten" class="btn btn-success mx-1" @click="process(item)"><i
                class="fas fa-clipboard-check"></i></button>

          </div>
        </div>
      </div>
    </div>
    <b-modal id="task-files-modal" :title="modalTitle" no-close-on-backdrop hide-footer>
      <div v-if="selectedTask && selectedTask.files && selectedTask.files.length > 0">
        <table class="table table-striped table-sm">
          <thead>
          <tr>
            <th>Dateiname</th>
            <th class="text-center">Kommentar</th>
            <th class="text-center">Aktion</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="file in selectedTask.files" :key="'file' + file.id">
            <td>{{ file.file.name | truncate(50, '...') }}</td>
            <td v-if="file.comment">{{ file.comment | truncate(50, '...') }}</td>
            <td class="text-center" v-else>-</td>
            <td class="text-center">
              <button @click="download(file.file.id, Module.TASK)" class="btn btn-outline-info download" type="button">
                <i class="fas fa-download"/></button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {DateHelper as dateHelper} from "../../model/Date";
import moment from "moment/moment";
import {Task} from "@/model/Task";
import {func} from "@/scripts/scripts";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {Module} from "@/model/Constants";
import jquery from "jquery";

export default {
  components: {ListItemComponent},
  data() {
    return {
      selectedTask: null
    }
  },
  computed: {
    Module() {
      return Module
    },
    Task() {
      return Task
    },
    dateHelper() {
      return dateHelper
    },
    modalTitle() {
      return this.selectedTask ? `${this.selectedTask.title}: Dateien` : 'Dateien';
    }
  },
  props: ['tasks', 'isCheckin', 'isDashboard'],
  methods: {
    isPastDue(date) {
      return moment().isAfter(moment(date), 'day');
    },
    openModal(item) {
      this.$emit('openModal', item)
    },
    chats(item) {
      this.$emit('chats', item)
    },
    notizen(item) {
      this.$emit('notizen', item)
    },
    process(item) {
      this.$emit('process', item)
    },
    processHygiene(item) {
      this.$emit('hygiene', item)
    },
    openFilesModal(item) {
      this.selectedTask = item;
      this.$bvModal.show('task-files-modal');
    }
  }
};
</script>

<style scoped>
.card-height {
  height: 17rem;
}
</style>

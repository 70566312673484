
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {GenericError, Permission, PermissionModul, ReportDTO} from "@/model/dto";
import {Report} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import ReportDataView from "@/views/emeld/data/ReportDataView.vue";
import ReportNotizView from "@/views/emeld/ReportNotizView.vue";
import ReportProcessView from "@/views/emeld/data/ReportProcessView.vue";
import ReportTaskView from "@/views/emeld/ReportTaskView.vue";
import {Actions} from "@/model/Actions";


const DataProps = Vue.extend({
  props: {
    id: String,
  }
})
@Component({
  components: {
    ReportTaskView,
    ReportProcessView,
    ReportNotizView,
    ReportDataView,
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class ReportEditView extends mixins<GenericMixin<ReportDTO, GenericError>>(GenericMixin, DataProps) {
  data = new Report();
  error = new GenericError();
  showTabs = false
  tabIndex = 0
  processKey = 0
  @Prop({default: 0}) idx: number

  async created() {
    this.init();
  }


  init = function () {
    this.tabIndex = Number(this.idx)

    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = EmeldApi.getReport(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean, send) {
    const p = EmeldApi.putReport(this.data, send ? Actions.SEND : Actions.SAVE);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send emeldReportChanged event')
        bus.$emit(Event.emeldReportChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    const from = this.$store.state.route.from.fullPath
    if (from &&
        this.$store.state.route.from.name != this.$route.name &&
        (from.includes("report") || from.includes("emeld") || from.includes("task"))) {
      this.$router.push(from)
    } else {
      this.$router.push("/emeld/report")
    }
  }


  @Watch('tabIndex')
  public watchValue(newValue, oldvalue) {
    const newRoute = "/emeld/report/edit/" + this.$props.id + "/" + this.tabIndex
    if (this.$route.fullPath != newRoute) {
      this.$router.replace(newRoute)
    }
  }


  get editPermission() {
    if (!this.data.sent) {
      if (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Create)) {
        return true;
      }
    }

    return (this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update))
        || this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Update_Responsible);

  }
}

import axios, {CanceledError} from 'axios'
import store from '../store'
import NProgress from 'nprogress'
import router from '../router'
import {func} from '@/scripts/scripts'

import {v4 as uuid} from 'uuid';
import {ApiResponse, LastRequest} from "@/model/AbstractClasses";

export const apiClient = axios.create({
    withCredentials: !process.env.VUE_APP_DEVMODE,
    baseURL: process.env.VUE_APP_REWIS + "/"
})
export const silentClient = axios.create({
    withCredentials: !process.env.VUE_APP_DEVMODE,
    baseURL: process.env.VUE_APP_REWIS + "/"
})
export const javaClient = axios.create({
    baseURL: process.env.VUE_APP_REWIS_JAVA + "/"
})


function wrapRequest(config) {
    const token = store.getters.getToken

    let random = uuid();
    store.dispatch('addLoading', random);

    NProgress.start()
    config.headers['X-Application'] = 'api'
    if (process.env.VUE_APP_DEVMODE) {
        config.headers['X-Devmode'] = '1'
    }

    config.headers['x-request'] = random

    if (token) {
        config.headers['X-Token'] = 'Bearer ' + token
        config.headers.Authorization = 'Bearer ' + token
    } else if (func.isLocalStorage()) {
        var tmpToken = localStorage.getItem('token')
        if (tmpToken) {
            console.log('use token from local storage')
            config.headers['X-Token'] = 'Bearer ' + tmpToken
        }
    }
}

apiClient.interceptors.request.use(
    config => {
        const controller = new AbortController();

        if (config.method === 'put' || config.method === 'delete') {
            const path = config.url
            store.state.lastRequests = store.state.lastRequests.filter(e => e.timestamp > Date.now() - 1000)
            if (store.state.lastRequests.find(e => e.path === path) !== undefined) {
                console.error("echo request")
                controller.abort();
                return {
                    ...config,
                    signal: controller.signal
                };
            } else {
                store.state.lastRequests.push(new LastRequest(path))
                wrapRequest(config);
                return {
                    ...config,
                    signal: controller.signal
                };
            }
        } else {
            wrapRequest(config);
            return {
                ...config,
                signal: controller.signal
            };
        }
    },
    error => {
        console.log(error)
        Promise.reject(error)
    })

// axios response interceptor
apiClient.interceptors.response.use(
    function (response) {
        if (func.isset(response.headers['x-request'])) {
            store.dispatch('removeLoading', response.headers['x-request'])
        }

        if (store.getters.getLoading.length === 0) {
            NProgress.done() // Set the loading progress bar (end...)
        }
        return response
    },
    function (error) {
        if (error instanceof CanceledError) {
            return Promise.reject(error)
        }

        if (error.response.headers != undefined && func.isset(error.response.headers['x-request'])) {
            store.dispatch('removeLoading', error.response.headers['x-request'])
        } else if (store.getters.getLoading.length === 1) {
            store.dispatch('clearLoading')
            NProgress.done()
        }

        if (store.getters.getLoading.length === 0) {
            NProgress.done()
        }

        if (router.currentRoute !== null && router.currentRoute !== undefined && router.currentRoute.name !== null && router.currentRoute.name.endsWith('login')) {
            return Promise.reject(new ApiResponse(error))
        } else {
            if (error.response.status === 401) {
                router.push("/login")
            }
        }
        return Promise.reject(new ApiResponse(error))
    }
)

silentClient.interceptors.request.use(
    config => {
        const token = store.getters.getToken

        config.headers['X-Application'] = 'api'

        if (token) {
            config.headers['X-Token'] = 'Bearer ' + token
            config.headers.Authorization = 'Bearer ' + token
        } else if (func.isLocalStorage()) {
            var tmpToken = localStorage.getItem('token')
            if (tmpToken) {
                console.log('use token from local storage')
                config.headers['X-Token'] = 'Bearer ' + tmpToken
            }
        }
        // config.headers['Content-Type'] = 'application/json';
        return config
    },
    error => {
        console.log(error)
    })

javaClient.interceptors.request.use(
    config => {
        let token = store.getters.getToken
        if (!token && func.isLocalStorage()) {
            var tmpToken = localStorage.getItem('token')
            if (tmpToken) {
                console.log('use token from local storage')
                token = tmpToken
            }
        }

        config.headers.Authorization = 'Bearer ' + token
        return config
    },
    error => {
        console.log(error)
        Promise.reject(error)
    })

export default {}


import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {UserDateTypDTO} from "@/model/dto";
import UserDateView from "@/views/user/UserDateView.vue";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import UserQualificationReminderListView from "@/views/user/UserQualificationReminderListView.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";


@Component(
    {
      components: {UserQualificationReminderListView, ListItemComponent, PersonenkreisTag, UserDateView},
    }
)
export default class UserDateList extends Vue {
  dateTypes: UserDateTypDTO[] = []
  showModal = false
  maxTags: number;
  clb = null;
  selectedElem = null
  key = 0

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']
    this.dateTypes = await this.$store.dispatch("user/fetchDateTypesOu")
    this.selectedElem = this.$store.state.user.filter.dateType
    this.clb = (dateType) => {
      this.selectedElem = dateType
      this.key++
    }
    bus.$on(Event.userDateCategoryFilter, this.clb)
  }

  destroyed() {
    bus.$off(Event.userDateCategoryFilter, this.clb)
  }

  openModal() {
    this.showModal = true
  }
}

import { render, staticRenderFns } from "./TaskTemplateListView.vue?vue&type=template&id=5a99b1bd&"
import script from "./TaskTemplateListView.vue?vue&type=script&lang=ts&"
export * from "./TaskTemplateListView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UpsertForm, UpsertFormError} from "@/model/FormVersion";

@Component({
  components: {
  }
})
export default class FormDataView extends mixins<GenericMixin<UpsertForm, UpsertFormError>>(GenericMixin) {
  @Prop() value: UpsertForm;
  @Prop() error: UpsertFormError;
  @Prop() private modul;


  data: UpsertForm = this.$props.value;

  loadingComplete = false

  async created() {
    window.scrollTo(0, 0);
    if (this.modul) {
      this.value.typ = this.modul
    }
    this.init()
  }


  async init() {
    this.loadingComplete = true
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

}

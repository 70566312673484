
import {Component, Prop, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {KfzFileDTO, Permission, PermissionModul} from "@/model/dto";
import moment from "moment/moment";
import {FileApi} from "@/services/FileApi";
import KfzFileEditModal from "@/views/kfz/modal/KfzFileEditModal.vue";
import {KfzFile} from "@/model/File";
import {Module} from "@/model/Constants";
import {v4 as uuid} from 'uuid';


@Component(
    {
      computed: {
        Module() {
          return Module
        }
      },
      components: {KfzFileEditModal},
    }
)
export default class KfzFileKfzView extends Vue {
  @Prop() private kfz;
  data: KfzFileDTO | null = null
  files: KfzFileDTO[] = []
  modalId = 'kfz-file-edit-modal'
  withArchive = false

  async init() {
    this.files = await FileApi.getKfzFileByKfz(this.$props.kfz.id, this.withArchive);
    if (this.withArchive) {
      this.files = this.files.filter(file => file.archive)
    }
  }

  async updateTable(data) {
    await this.init()
  }

  async created() {
    this.$root.$emit('loadingStart')
    await this.init()
    this.$root.$emit('loadingDone')
  }

  newFile() {
    this.data = new KfzFile()
    this.data.kfz = this.$props.kfz
    this.$bvModal.show(this.modalId)
  }

  edit(rowdata) {
    if (!this.editPermission(rowdata)) return;

    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'kfz-file-edit-modal' + uuid()
  }

  toMoment(d) {
    return moment(d)
  }


  get getFileData() {
    return this.files.sort((a, b) => {
      let sort = b.statusNumeric - a.statusNumeric
      if (sort == 0 && a.fileCategory && b.fileCategory) {
        sort = a.fileCategory.name.localeCompare(b.fileCategory.name)
      }
      return sort;
    })
  }

  getFileCategory(file: KfzFileDTO) {
    if (file.fileCategories && file.fileCategories.length > 0) {
      return file.fileCategories.map(fc => fc.name).join(", ");
    } else if (file.fileCategory && file.fileCategory.name) {
      return file.fileCategory.name;
    } else if (file.manualFile) {
      return file.manualFile.hersteller + ', ' + file.manualFile.modell + ', ' + file.manualFile.version;
    } else if (file.downloadFile && file.downloadFile.name) {
      return file.downloadFile.name;
    } else {
      return '-';
    }
  }

  get filteredFileData() {
    const seen = new Set();
    return this.getFileData.filter(file => {
      if (!file.downloadFile) {
        return true;
      }
      const key = file.downloadFile.id;
      if (seen.has(key)) {
        return false;
      } else {
        seen.add(key);
        return true;
      }
    });
  }

  async archiveToggle(checked) {
    this.$root.$emit('loadingStart')
    this.withArchive = !this.withArchive
    await this.init()
    this.$root.$emit('loadingDone')
  }

  editPermission(file) {
    if (file && file.fileCategories && file.fileCategories.find(c => c.confidential)) {
      return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Update_Vertrauliche)
    }

    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Update)
  }

  downloadPermission(file) {
    if (file.fileCategories && file.fileCategories.find(c => c.audit)) {
      return this.$store.getters.hasPermission(PermissionModul.Audit, Permission.Access)
    }

    if (file.fileCategories && file.fileCategories.find(c => c.confidential)) {
      return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Access_Vertraulich)
    }

    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Access)
  }
}



import {Component, Vue} from "vue-property-decorator";
import {Route} from "vue-router";
import FileLexNavComponent from "@/views/file/components/FileLexNavComponent.vue";
import {Permission, PermissionModul} from "@/model/dto";

@Component(
    {
      components: {FileLexNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)

export default class FileLexView extends Vue {
  tableRoute = true

  handleRoute() {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'docs' && this.$store.getters.hasPermission(PermissionModul.FILELEX, Permission.Overview)) {
      this.$router.push("/docs/tree/docs")
    }
  }

  created() {
    this.handleRoute()
  }
}

import { render, staticRenderFns } from "./MaterialFileEditModal.vue?vue&type=template&id=4964f26e&scoped=true&"
import script from "./MaterialFileEditModal.vue?vue&type=script&lang=ts&"
export * from "./MaterialFileEditModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4964f26e",
  null
  
)

export default component.exports
import {AdditionalFieldDTO, FormDTO, FormRowDTO, FormVersionDTO, UpsertFormDTO, UserResponseDTO} from "@/model/dto";
import {AbstractClass} from "@/model/AbstractClasses";
import {FormApi} from "@/services/FormApi";


export class FormVersion extends AbstractClass implements FormVersionDTO {
   
    rows: FormRowDTO[];
    template: boolean | null;
    archive: boolean | null;
    released: boolean | null;
    form: FormDTO | null;
    versionNumber: number | null;
    releaseDT: string | null;
    releaseUser: UserResponseDTO | null;
    formName: string | null;
    formTyp: string | null;

    static async copy(data, formVersionId) {
        const template = await FormApi.getFormVersion(formVersionId)
        data = template.data

        data.id = null;
        data.released = false
        data.rows.forEach(row => {
            row.id = null;
            row.fields.forEach(field => field.id = null)
        })

        return data;
    }

    images: string[] = [];
}

export class FormRow extends AbstractClass implements FormRowDTO {
    fields: AdditionalFieldDTO[];
   
    sortOrder: number | null;
    description: string | null;
    isDescription: boolean | null;
    lineBottom: string | null;
    lineTop: string | null;
    title: string | null;
    fillRow: boolean | null = false;
}

export class UpsertForm extends FormVersion implements UpsertFormDTO
{
    constructor() {
        super();
    }

    name: string | null;
    typ: number | null;
}

export class UpsertFormError extends AbstractClass implements UpsertFormDTO, FormVersionDTO {
   
    name: string | null;
    version: FormVersionDTO | null;
    archive: boolean | null;
    form: FormDTO | null;
    released: boolean | null;
    rows: FormRowDTO[];
    template: boolean | null;
    versionNumber: number | null;
    releaseDT: string | null;
    releaseUser: UserResponseDTO | null;
    typ: number | null;
    formName: string | null;
    formTyp: string | null;
    images: string[];
}


import {Component, Vue} from 'vue-property-decorator';
import '@/scripts/dataTables_German'
import FehlerAnregung from "@/components/FehlerAnregung.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {MaterialDTO, Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import jquery from "jquery";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import AdminDashboard from "@/views/adminDashboard/AdminDashboard.vue";
import UserDashboard from "@/views/userDashboard/UserDashboard.vue";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import {Route} from "vue-router";
import CheckinDashboard from "@/views/checkin/CheckinDashboard.vue";

@Component({
  components: {CheckinDashboard, UserDashboard, AdminDashboard, ListItemComponent, FehlerAnregung, Multiselect, FontAwesomeIcon},
  watch: {
    '$route'(val: Route) {
      this.handleRoute()
    }
  }
})


export default class HomeView extends Vue {
  field: MaterialDTO[] = []
  materialOpts: MaterialDTO[] = []
  materialList: MaterialDTO[] = []
  clearOnSelect = true

  showMyDashboard = false
  showAdminDashboard = false
  showCheckinDashboard = false

  routeName = null


  async created() {
    this.handleRoute()
    this.materialOpts = await this.$store.dispatch("material/fetchStandorte")
  }

  handleRoute() {
    this.routeName = this.$route.name
    if (this.routeName == "mydashboard") {
      this.showMyDashboard = true
      this.showAdminDashboard = false
      this.showCheckinDashboard = false
    } else if (this.routeName == "admindashboard" && this.hasAdminDashboardPermission) {
      this.showMyDashboard = false
      this.showAdminDashboard = true
      this.showCheckinDashboard = false
    } else if (this.routeName == "wachbuch" && this.hasCheckinPermission) {
      this.showMyDashboard = false
      this.showAdminDashboard = false
      this.showCheckinDashboard = true
    } else if (this.routeName == "dashboard") {
      this.showMyDashboard = !this.hasAdminDashboardPermission
      this.showAdminDashboard = this.hasAdminDashboardPermission
      this.showCheckinDashboard = false
    } else {
      this.showMyDashboard = true
      this.showAdminDashboard = false
      this.showCheckinDashboard = false
    }

  }


  input(option) {
    this.field = []
    jquery('.multiselect input').focus()
    this.materialList = [...new Map([...this.materialList, ...[option]].map((item) => [item["id"], item])).values()];
  }

  remove(id) {
    this.materialList = this.materialList.filter(m => m.id != id)
  }

  get materialOptions() {
    return this.materialOpts.filter(opt => !this.materialList.map(o => o.id).includes(opt.id))
  }

  get hasCheckinPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Create))
  }

  get hasAdminDashboardPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Emeld, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Task, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Kfz_Task, Permission.Overview)

        || this.$store.getters.hasPermission(PermissionModul.User, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.User_Dates, Permission.Overview)

        || this.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Kfz_Dates, Permission.Overview)

        || this.$store.getters.hasPermission(PermissionModul.Medprodukt, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Medprodukt_Dates, Permission.Overview)

        || this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Material_Files, Permission.Overview)
        || this.$store.getters.hasPermission(PermissionModul.Material_Dates, Permission.Overview)
  }


}

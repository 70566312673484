
import {Component, Prop, Vue} from "vue-property-decorator";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {EinweisungDTO, EinweisungOverviewResponseDTO} from "@/model/dto";
import {EinweisungApi} from "@/services/EinweisungApi";
import {EinweisungOverviewRequest} from "@/model/Einweisung";
import GegenstandListItemComponent from "@/views/shared/components/GegenstandListItemComponent.vue";
import {DateHelper} from "@/model/Date";
import {GegenstandListItem} from "@/model/Shared";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";


@Component(
    {
      components: {ListItemComponent, GegenstandListItemComponent}
    }
)
export default class EinweisungOverviewTable extends Vue {
  data: EinweisungOverviewResponseDTO = null;
  gegenstandList: GegenstandListItem[] = []
  callback = null
  @Prop() private requestDto;

  async created() {
    if (this.requestDto) {
      this.$root.$emit('loadingStart')
      await this.init(this.requestDto)
    } else {
      this.callback = (data) => {
        this.$root.$emit('loadingStart')
        this.init(data)
      }
      bus.$on(Event.einweisungOverviewChanged, this.callback)
    }
  }

  async init(req: EinweisungOverviewRequest) {
    this.gegenstandList = []
    this.data = await EinweisungApi.getOverview(req)


    this.data.gegenstaende.forEach((gegenstand) => {
      let type = ""
      let dto = null
      if (gegenstand.qualifikation != null) {
        dto = gegenstand.qualifikation
      } else if (gegenstand.kfz != null) {
        dto = gegenstand.kfz
      } else if (gegenstand.material != null) {
        dto = gegenstand.material
      } else if (gegenstand.medProdukt != null) {
        dto = gegenstand.medProdukt
      } else if (gegenstand.kfzCategory != null) {
        dto = gegenstand.kfzCategory
      } else if (gegenstand.materialKlasse != null) {
        dto = gegenstand.materialKlasse
      }


      if (dto) this.gegenstandList.push(new GegenstandListItem(dto))
    })
    this.$root.$emit('loadingDone')
  }

  getTooltipInfos(col) {
    if (!col || !col.length) return "Keine Einweisung vorhanden"
    return col.map(einweisung => 'ID ' + einweisung.id + ' ' + this.getInfos(einweisung)).join('; ');
  }

  getInfos(einweisung: EinweisungDTO) {
    let arr = []
    let result = DateHelper.parseDate(einweisung.datum, "")
    if (result) {
      arr.push(result)
    }

    if (einweisung.orgUnit.id != this.$store.getters.getLocation.id) {
      arr.push(einweisung.orgUnit.name)
    }

    return (arr.length > 0) ? ("(" + arr.join(", ") + ")") : ""
  }

  addEinweisung(user, idx2) {
    if (this.data.gegenstaende[idx2] && this.data.gegenstaende[idx2].qualifikation) {
      return;
    }
    this.$router.push({name: 'einweisungcreate', params: {user: user.user, gegenstand: this.data.gegenstaende[idx2]}})
  }

  destroyed() {
    if (this.callback) {
      bus.$off(Event.einweisungOverviewChanged, this.callback)
    }
  }
}


import {Component, Vue, Watch} from "vue-property-decorator";
import {UserOrgUnit, UserProfile} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {AuthApi} from "@/services/AuthApi";
import {ConstantsDTO, OrgUnitDTO, OrgUnitTreeDTO, Permission, PermissionModul} from "@/model/dto";
import UserPermissionForeignTableView from "@/views/user/UserPermissionForeignTableView.vue";
import {v4 as uuid} from 'uuid';
import {UsersApi} from "@/services/UserApi";
import {OrgUnit} from "@/model/OrgUnit";
import {Multipane, MultipaneResizer} from 'vue-multipane';

const DataProps = Vue.extend({
  props: {
    value: UserProfile,
    error: UserProfile
  }
})

@Component({
  components: {UserPermissionForeignTableView, OrgUnitSelectorLiquor, Multipane, MultipaneResizer}
})
export default class UserPermissionView extends mixins<GenericMixin<UserProfile, UserProfile>>(GenericMixin, DataProps) {
  data = this.$props.value;
  selectedOrgUnit = this.$store.getters.getLocation.id
  selectedOrgUnitName = this.$store.getters.getLocation.text
  userPermissionForeignTableKey = uuid()
  userPermissionOwnTableKey = uuid()
  containerWidth: 200
  constants: ConstantsDTO
  preLoaded = false
  userPermissions: OrgUnitTreeDTO | null
  userOrgUnits: OrgUnitDTO[] = []

  isMemberOfOrgUnit = false
  permissionOrgUnits = []
  readOnly = false

  async changeLocation({id, name}) {
    this.preLoaded = false
    this.selectedOrgUnit = id
    this.selectedOrgUnitName = name
    this.userPermissions = await AuthApi.getUserPermissions(this.$props.value.id, this.selectedOrgUnit)
    this.isMemberOfOrgUnit = this.userOrgUnits.find((orgUnit) => orgUnit.id === this.selectedOrgUnit) !== undefined;

    this.readOnly = this.permissionOrgUnits.find((orgUnit) => orgUnit.id === this.selectedOrgUnit) === undefined;

    this.userPermissionForeignTableKey = uuid()
    this.userPermissionOwnTableKey = uuid()
    this.preLoaded = true
  }

  async created() {
    this.$root.$emit('loadingStart')
    this.permissionOrgUnits = await AuthApi.getPermissionOrgUnits()

    this.constants = this.$store.getters.getConstants;
    this.userPermissions = await AuthApi.getUserPermissions(this.$props.value.id, this.selectedOrgUnit)

    this.userOrgUnits = await UsersApi.getOrgUnitsOfUser(this.$props.value.id)
    this.isMemberOfOrgUnit = this.userOrgUnits.find((orgUnit) => orgUnit.id === this.selectedOrgUnit) !== undefined;

    this.preLoaded = true
    this.$root.$emit('loadingDone')
  }

  userOrgUnitsChanged(checked) {
    let dto = new UserOrgUnit(this.$props.value.id, this.selectedOrgUnit)
    if (!checked) {
      if (this.selectedOrgUnit == this.$store.getters.getLocation.id) {
        this.$confirm('Möchten Sie den Benutzer wirklich aus dem Mitarbeitendenverzeichnis entfernen? Sie verlieren dadurch den Zugriff auf diesen!', 'Benutzer entfernen', 'warning', {
          confirmButtonText: 'Ja',
          cancelButtonText: 'Nein'
        })
            .then(() => {
              UsersApi.removeUserOrgUnit(dto)
                  .then(() => {
                    this.userOrgUnits = this.userOrgUnits.filter((orgUnit) => orgUnit.id !== this.selectedOrgUnit)
                  })
                  .catch(() => {
                    this.isMemberOfOrgUnit = true
                    this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
                  })
            })
            .catch(() => {
              this.isMemberOfOrgUnit = true
            })
      } else {
        UsersApi.removeUserOrgUnit(dto)
            .then(() => {
              this.userOrgUnits = this.userOrgUnits.filter((orgUnit) => orgUnit.id !== this.selectedOrgUnit)
            })
            .catch(() => {
              this.isMemberOfOrgUnit = true
              this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
            })
      }
    } else {
      UsersApi.addUserOrgUnit(dto)
          .then(() => {
            let o = new OrgUnit()
            o.id = this.selectedOrgUnit
            o.name = ""
            o.sName = ""
            this.userOrgUnits.push(o)
          })
          .catch(() => {
            this.isMemberOfOrgUnit = false
            this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
          })
    }
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Rechtevergabe)
  }
}


import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {LevelDTO, Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {Level, Status, Tag} from "@/model/Emeld";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import EmeldTagDataView from "@/views/emeld/data/EmeldTagDataView.vue";
import emeld from "@/store/modules/emeld";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import FormCreateModal from "@/views/form/components/FormCreateModal.vue";
import {bus} from "@/main";
import {Event, Module} from "@/model/Constants";
import LabelComponent from "@/components/LabelComponent.vue";

@Component({
  computed: {
    emeld() {
      return emeld
    },
    Module() {
      return Module
    }
  },
  components: {
    LabelComponent,
    FormCreateModal,
    TaskNotizModal,
    EmeldTagDataView,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class EmeldLevelDataView extends mixins<GenericMixin<LevelDTO, LevelDTO>>(GenericMixin) {
  @Prop() value: Level;
  @Prop() error: Level;
  @Prop() noTags: boolean;

  data: Level = this.$props.value;

  loadingComplete = false

  statusOptions = []
  levelKlasseOptions = []
  formOptions = []
  personenkreisOptions = []

  showFormCreateModal = false
  formTakeOverCallback = null

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.statusOptions = await this.$store.dispatch("emeld/fetchStatus")
    this.levelKlasseOptions = await this.$store.dispatch("emeld/fetchLevelKlassen")
    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == 2)
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.data.defaultStatus = this.statusOptions.find(item => item.name == 'Erfasst')

    this.loadingComplete = true
  }

  addStatus(value) {
    let status = new Status()
    status.name = value
    status.id = null
    this.data.status.push(status)
  }

  removeTag(idx) {
    this.data.tags.splice(idx, 1)
  }

  addTag() {
    let tag = new Tag();
    tag.info = []
    tag.bewertungType = 0
    this.data.tags.push(tag);

    this.$nextTick(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    })
  }

  newForm() {
    bus.$off(Event.formTakeOver)
    bus.$on(Event.formTakeOver, (formEvent) => {
      this.data.form = formEvent.data.form
    })

    this.showFormCreateModal = true
  }

  statusOptionsChanged() {
    if (this.data.defaultStatus) {
      let anlageStatusName = this.data.defaultStatus.name
      if (!this.data.status.find(item => item.name == anlageStatusName)) {
        this.data.defaultStatus = null
      }
    }
  }

  zustaendigkeitChanged($event) {
    console.log($event)
    this.$emit('zustaendigkeit', $event)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

  get formReleasePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Emeld_Settings, Permission.Formulare_Release)
  }
}

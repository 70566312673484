
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AdditionalFieldDTO, AdditionalFieldResultDTO, KfzKennungDTO, KfzResponseDTO, MaterialDTO, MedProduktDTO} from "@/model/dto";
import Multiselect from '@/libs/multiselect'
import NumberForm from "@/components/NumberForm.vue";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import DateForm from "@/components/DateForm.vue";
import {Module, UserId} from "@/model/Constants";
import {_} from 'vue-underscore';
import FileUpload from "@/components/FileUpload.vue";
import {mixins} from "vue-class-component";
import DropzoneMixin from "@/views/DropzoneMixin.vue";

@Component({
  computed: {
    UserId() {
      return UserId
    }
  },
  components: {FileUpload, DateForm, ListItemComponent, MedProduktChooser, NumberForm, Multiselect},
})export default class AdditionalFieldResultForm extends mixins(DropzoneMixin) {
  @Prop() private value: AdditionalFieldResultDTO;
  @Prop() private error;
  @Prop() private id;
  @Prop() private field: AdditionalFieldDTO;
  @Prop() readOnly;
  @Prop() moduleId;

  fileField = 'file'
  fileSimple = true


  workingValue: AdditionalFieldResultDTO | null = null;
  taggingOptions = []
  taggingSelected = []

  medproduktOpts = []
  kfzOpts = []
  userOpts = []
  materialOpts = []
  kennungenOpts = []
  dienstOpts = []
  qualiOpts = []
  uploadKey = 0

  created() {
    this.validateKilometerDebounce = _.debounce(this.validateKilometerDebounce, 2000)

    this.init(this.$props.value)
  }

  async init(val) {
    const checkin = this.$store.getters["checkin/getCheckin"]

    if (this.field.fieldTyp == 7 && this.field.vorbelegung) {
      val.fieldValue = this.field.vorbelegung
    } else if (this.field.fieldTyp == 20) {
      if (checkin && this.field.bCheckinBezug) {
        this.medproduktOpts = await this.$store.dispatch("checkin/fetchMedProdukte")
      }

      if (this.medproduktOpts.length == 0) {
        this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukte")
      }
      this.medproduktOpts = this.medproduktOpts.filter(prod => true || prod.holder == false)
    } else if (this.field.fieldTyp == 21 || this.field.fieldTyp == 24) {
      this.kfzOpts = await this.$store.dispatch("kfz/fetchKfz")
    } else if (this.field.fieldTyp == 22) {
      let users = []

      if (checkin && this.field.bCheckinBezug) {
        users = this.$store.getters["checkin/getUsers"]
      }

      if (users.length == 0) {
        users = await this.$store.dispatch("user/fetchUser")
      }
      this.userOpts = [...users, ...[{
        id: UserId.FREITEXT,
        formatted: 'Freitext'
      }]]
    } else if (this.field.fieldTyp == 23) {
      if (checkin && this.field.bCheckinBezug) {
        this.materialOpts = await this.$store.dispatch("checkin/fetchMaterial")
      }

      if (this.materialOpts.length == 0) {
        this.materialOpts = await this.$store.dispatch("material/fetchMaterial")
      }

    } else if (this.field.fieldTyp == 25) {
      this.kennungenOpts = await this.$store.dispatch("kfz/fetchKennungen")
    } else if (this.field.fieldTyp == 26) {
      this.dienstOpts = await this.$store.dispatch("checkin/fetchDienste")
    } else if (this.field.fieldTyp == 27) {
      this.qualiOpts = await this.$store.dispatch("user/fetchQualis")
    }

    if (val && !val.id && this.field.checkinField && checkin) {
      if (this.field.fieldTyp == 21 || this.field.fieldTyp == 24) {
        val.kfz = checkin[this.field.checkinField]
        if (val.kfz.originalId) {
          val.kfz.id = val.kfz.originalId
        }
      } else if (this.field.fieldTyp == 22) {
        val.user = checkin[this.field.checkinField]
        if (val.user.originalId) {
          val.user.id = val.user.originalId
        }
        val.userText = checkin[this.field.checkinField + 'Text']
      } else if (this.field.fieldTyp == 25) {
        val.kennung = checkin[this.field.checkinField]
        if (val.kennung.originalId) {
          val.kennung.id = val.kennung.originalId
        }
      } else if (this.field.fieldTyp == 26) {
        val.dienst = checkin[this.field.checkinField]
        if (val.dienst.originalId) {
          val.dienst.id = val.dienst.originalId
        }
      } else {
        val.fieldValue = checkin[this.field.checkinField]
      }
    }


    if (this.field.fieldTyp == 20 && this.field.filterGeraeteart) {
      this.medproduktOpts = this.medproduktOpts.filter(medP => medP.ewo && medP.ewo.geraeteart && medP.ewo.geraeteart.id == this.field.filterGeraeteart.id)
    }

    if (this.field.fieldTyp == 23 && this.field.filterMaterialArt) {
      this.materialOpts = this.materialOpts.filter(material => {
        console.log(material.art)
        let objs = [...material.klassen, ...material.art.klassen, material.art]
        let ids = objs.map(kl => kl.id)
        return ids.includes(this.field.filterMaterialArt.id)
      })
    }

    if (val) {
      this.$nextTick(() => {
        this.workingValue = Vue.util.extend(val)
        this.convertValues()
      });
    }
  }

  convertValues() {
    if (this.field.fieldTyp == Module.MEDPRODUKT && !Array.isArray(this.workingValue.medProdukt)) {
      this.workingValue.medProdukt = this.workingValue.medProdukt ? [(this.workingValue.medProdukt as MedProduktDTO)] : []
    } else if (this.field.fieldTyp == Module.KFZ && !Array.isArray(this.workingValue.kfz)) {
      this.workingValue.kfz = this.workingValue.kfz ? [(this.workingValue.kfz as KfzResponseDTO)] : []
    } else if (this.field.fieldTyp == Module.MATERIAL && !Array.isArray(this.workingValue.material)) {
      this.workingValue.material = this.workingValue.material ? [(this.workingValue.material as MaterialDTO)] : []
    } else if (this.field.fieldTyp == Module.KENNUNG && !Array.isArray(this.workingValue.kennung)) {
      this.workingValue.kennung = this.workingValue.kennung ? [(this.workingValue.kennung as KfzKennungDTO)] : []
    } else if (this.field.fieldTyp == 24 && !Array.isArray(this.workingValue.kfz)) {
      this.workingValue.kfz = this.workingValue.kfz ? [(this.workingValue.kfz as KfzResponseDTO)] : []
    }
  }

  input(e) {
    if (!this.workingValue.user || this.workingValue.user.id != UserId.FREITEXT) {
      this.workingValue.userText = "";
    }

    this.convertValues()
    this.$emit("input", this.workingValue);
    this.$emit("change", this.workingValue);
  }

  get options() {
    if (this.field && this.field.options.length > 0) {
      return [...this.field.options].sort((a, b) => a.localeCompare(b));
    }
    return []
  }

  numberFormInputHandler() {
    this.validateKilometerDebounce()
  }

  validateKilometerDebounce() {
    if (this.workingValue.kfzKmBeginn && this.workingValue.kfzKmEnde) {
      if (this.workingValue.kfzKmBeginn > this.workingValue.kfzKmEnde) {
        this.$alert("Der Startwert darf nicht größer als der Endwert sein", "Fehler", "error");
        this.workingValue.kfzKmEnde = null
      } else if (this.workingValue.kfzKmEnde - this.workingValue.kfzKmBeginn > 100) {
        this.$confirm("Die Differenz von Start- und Endkilometer ist sehr groß. Sind Sie sicher, dass Sie diesen Wert eingeben möchten?", "", "warning", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
        }).then(() => {
          return
        }).catch(() => {
          this.workingValue.kfzKmEnde = null
        });
      }
    }
  }


  get workValue() {
    return this.workingValue
  }

  set workValue(val) {
    this.$emit("input", this.workingValue)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
    this.uploadKey++
  }

}

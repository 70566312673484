
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import FobiNavComponent from "@/views/fobi/components/FobiNavComponent.vue";
import {FobiApi} from "@/services/FobiApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {FobiNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class FobiView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'fobi' && this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Overview)) {
      this.$router.push("/fobi/fobitable")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.fobiArchiveRequest, (payload: ObjectEvent<any>) => this.archivFobi(payload.data))
    bus.$on(Event.fobiKombiThemaArchiveRequest, (payload: ObjectEvent<any>) => this.archivKombiThema(payload.data))
    bus.$on(Event.fobiZeitraumArchiveRequest, (payload: ObjectEvent<any>) => this.archivZeitraum(payload.data))
  }

  destroyed() {
    bus.$off(Event.fobiArchiveRequest)
    bus.$off(Event.fobiKombiThemaArchiveRequest)
    bus.$off(Event.fobiZeitraumArchiveRequest)
  }

  archivFobi(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie Forbildung ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FobiApi.archivFobi(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.fobiArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  archivKombiThema(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie das Kombi-Thema ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FobiApi.archiveKombiThema(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.fobiKombiThemaArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  archivZeitraum(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie das Zeitraum ' + data.name + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FobiApi.archiveZeitraum(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.fobiZeitraumArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}


import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import EinsatzNavComponent from "@/views/einsatz/components/EinsatzNavComponent.vue";
import {EinsatzApi} from "@/services/EinsatzApi";
import {CheckinApi} from "@/services/CheckinApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {EinsatzNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EinsatzView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'einsatz'
        && this.$store.getters.hasPermission(PermissionModul.Checkin, Permission.Overview)) {
      this.$router.push("/einsatz/checkinoverview")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.einsatzRemoveRequest, (payload: ObjectEvent<any>) => this.removeEinsatz(payload.data))
    bus.$on(Event.checkinArchiveRequest, (payload: ObjectEvent<any>) => this.removeCheckin(payload.data))

  }

  destroyed() {
    bus.$off(Event.einsatzRemoveRequest)
    bus.$off(Event.checkinArchiveRequest)

  }

  removeEinsatz(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie diesen Einsatz ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EinsatzApi.removeEinsatz(data.id)
          .then(() => {
            bus.$emit(Event.einsatzArchived, new ObjectEvent(data.id, {id: data.id, archive: !data.archive}));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }

  removeCheckin(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie diesen Checkin ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      CheckinApi.removeCheckin(data.id)
          .then(() => {
            bus.$emit(Event.checkinArchived, new ObjectEvent(data.id, {id: data.id, archive: !data.archive}));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}

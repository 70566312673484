import {Permission, PermissionModul} from "@/model/dto";
import DivaTables from "@/views/diva/DivaTables.vue";
import DivaView from "@/views/diva/DivaView.vue";
import AuktionEditView from "@/views/diva/AuktionEditView.vue";
import AuktionCreateView from "@/views/diva/AuktionCreateView.vue";

const moduleMeta = {}

export const divaRoutes = [
    {
        path: 'diva',
        name: 'diva',
        component: DivaView,
        children: [
            {
                path: 'auktionlist',
                name: 'auktionlist',
                component: DivaTables,
                meta: {
                    datatable: true,
                    loading: false,
                    title: 'Auktionen',
                    permission: [PermissionModul.Diva, Permission.Overview],
                    activeRoutes: ['auktionlist', 'auktioncreate', 'auktionedit']
                }
            },
            {
                path: 'auktion/create',
                name: 'auktioncreate',
                components: {
                    default: DivaTables,
                    CRUD: AuktionCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Diva, Permission.Access],
                }
            },
            {
                path: 'auktion/edit/:id',
                name: 'auktionedit',
                components: {
                    default: DivaTables,
                    CRUD: AuktionEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    meta: {
                        permission: [PermissionModul.Diva, Permission.Overview],
                    }
                }
            }
        ]
    }
]

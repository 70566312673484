
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {ReportDTO} from "@/model/dto";
import {Report} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import {Route} from "vue-router";
import ReportDataView from "@/views/emeld/data/ReportDataView.vue";
import {Actions} from "@/model/Actions";


@Component({
  components: {
    ReportDataView,
  },
  watch: {
    '$route'(val: Route) {
      this.init()
    }
  }
})
export default class ReportCreateView extends mixins<GenericMixin<ReportDTO, ReportDTO>>(GenericMixin) {
  data = new Report();
  error = new Report();


  async created() {
    this.init()
  }

  init() {
    this.data = new Report()
    this.data.tags = []
    this.data.addFields = []
    this.data.rows = []
    this.data.files = []
  }

  onSubmit(send: boolean) {
    const p = EmeldApi.putReport(this.data, send ? Actions.SEND : Actions.SAVE);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send emeldReportCreated event')
        bus.$emit(Event.emeldReportCreated, new ObjectEvent(data.data.id, data.data));
        this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push(this.$store.state.route.from.fullPath)
  }

}

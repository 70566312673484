
import {Component, Prop} from "vue-property-decorator";
import store from '@/store'
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UserPasswordDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";

@Component({
  components: {},
})
export default class UserChangePinView extends mixins<GenericMixin<UserPasswordDTO, UserPasswordDTO>>(GenericMixin) {
  @Prop({default: false}) force
  data: UserPasswordDTO = {
    password: '',
    passwordConfirm: '',
    id: store.getters.getUser.id
  }
  error: UserPasswordDTO = {
    password: '',
    passwordConfirm: '',
    id: 0
  }


  created() {
    if (this.force) {
      this.$alert('Bitte ändern Sie Ihr Passwort', 'Passwortänderung erforderlich', 'info');
    }
  }

  onSubmit() {
    const p = UsersApi.updatePassword(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true);
  }
}


import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import {EmeldApi} from "@/services/EmeldApi";
import EmeldNavComponent from "@/views/emeld/components/EmeldNavComponent.vue";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {EmeldNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class EmeldView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'emeld'
        && this.$store.getters.hasPermission(PermissionModul.Outsourcing, Permission.Overview)) {
      this.$router.push("/emeld/report")
    } else if (this.$route.name == 'emeld'
        && this.$store.getters.hasPermission(PermissionModul.Outsourcing, Permission.Overview_Responsible)) {
      this.$router.push("/emeld/reportresponsible")
    }

  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.emeldLevelRemoveRequest, (payload: ObjectEvent<any>) => this.removeEmeldLevel(payload.data))
    bus.$on(Event.emeldLevelOuRemoveRequest, (payload: ObjectEvent<any>) => this.removeEmeldLevelOu(payload.data))

  }

  destroyed() {
    bus.$off(Event.emeldLevelRemoveRequest)
    bus.$off(Event.emeldLevelOuRemoveRequest)
  }

  removeEmeldLevel(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie diese Kategorie ' + data.name + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EmeldApi.removeLevel(data.id)
          .then(() => {
            bus.$emit(Event.emeldLevelChanged, new ObjectEvent(data.id, {id: data.id, archive: true}));
            bus.$emit(Event.emeldLevelOuChanged, new ObjectEvent(data.id, {id: data.id, archive: true}));

            this.$router.push({path: "/emeld/levelou"})
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }


  removeEmeldLevelOu(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie die Kategorie ' + data.level.name + ' wirklich in Ihrem Standort entfernen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      EmeldApi.removeLevelOu(data.id)
          .then(() => {
            bus.$emit(Event.emeldLevelOuChanged, new ObjectEvent(data.id, {id: data.id, archive: true}));
            this.$router.push({path: "/emeld/levelou"})
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}

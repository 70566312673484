import {apiClient} from './AbstractHTTPService'
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {
    EntityTaskDTO,
    EntityTaskFormRequestDTO,
    EntityTaskTemplateDTO,
    EntityTaskTemplateObjectDTO,
    EntityTtoCheckinTaskDTO,
    OrgUnitTreeDTO,
    TaskChatDTO,
    TaskDTO,
    TaskFormRequestDTO,
    TaskNotizDTO,
    TaskProcessDTO,
    TaskReportMapDTO,
    TaskRequestDTO,
    TaskStatusDTO,
    TaskStornoDTO,
    TaskTemplateDTO
} from "@/model/dto";
import store from "@/store";
import {Event} from "@/model/Constants";


export abstract class TaskApi {
    static async putTask(Task: TaskDTO): Promise<ApiResponse<TaskDTO>> {
        const response = await apiClient.put('/task/task/' + Task.id + '/', Task);
        //   store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async putTaskTemplate(Task: TaskTemplateDTO): Promise<ApiResponse<TaskTemplateDTO>> {
        const response = await apiClient.put('/task/tasktemplate/' + Task.id + '/', Task);
        //   store.dispatch("medprodukt/herstellerUpdate");
        return new ApiResponse(response);
    }

    static async putEntityTaskTemplate(Task: EntityTaskTemplateDTO): Promise<ApiResponse<EntityTaskTemplateDTO>> {
        const response = await apiClient.put('/task/entitytasktemplate/' + Task.id + '/', Task);
        store.dispatch("crudEvent", new CrudEvent(Event.entityTaskTemplateUpdate, response.data.data));
        return new ApiResponse(response);
    }

    static async putEntityTaskTemplateObject(Task: EntityTaskTemplateObjectDTO): Promise<ApiResponse<EntityTaskTemplateObjectDTO>> {
        const response = await apiClient.put('/task/entitytasktemplateobject/' + Task.id + '/', Task);
        return new ApiResponse(response);
    }

    static async putEntityTask(Task: EntityTaskDTO): Promise<ApiResponse<EntityTaskDTO>> {
        const response = await apiClient.put('/task/entitytask/' + Task.id + '/', Task);
        return new ApiResponse(response);
    }


    static async getMyTasks(request): Promise<TaskDTO[] | EntityTaskDTO[]> {
        const response = await apiClient.post('/task/mytask', request);
        return response.data.data;
    }

    static async getCheckinTasks(checkinId): Promise<TaskDTO[] | EntityTaskDTO[]> {
        const response = await apiClient.get('/task/checkintask/' + checkinId);
        return response.data.data;
    }

    static async putMyTask(dto: TaskProcessDTO): Promise<ApiResponse<TaskRequestDTO>> {
        const response = await apiClient.put('/task/mytask/' + dto.id + '/', dto);
        return new ApiResponse(response);
    }


    static async getTask(id): Promise<ApiResponse<TaskDTO | TaskTemplateDTO>> {
        const response = await apiClient.get('/task/task/' + id);
        return new ApiResponse(response);
    }

    static async getEntityTaskTemplate(id): Promise<ApiResponse<EntityTaskTemplateDTO>> {
        const response = await apiClient.get('/task/entitytasktemplate/' + id);
        return new ApiResponse(response);
    }

    static async getEntityTask(id): Promise<ApiResponse<EntityTaskDTO>> {
        const response = await apiClient.get('/task/entitytask/' + id);
        return new ApiResponse(response);
    }

    static async getEntityTaskTemplateList(): Promise<EntityTaskTemplateDTO[]> {
        const response = await apiClient.get('/task/entitytasktemplate/list');
        return response.data.data;
    }

    static async getEntityTaskTemplateObject(modulId): Promise<ApiResponse<EntityTaskTemplateObjectDTO[]>> {
        const response = await apiClient.get('/task/entitytasktemplateobject/' + modulId);
        return response.data.data;
    }

    static async getEntityTasks(request: EntityTaskFormRequestDTO): Promise<ApiResponse<EntityTaskDTO[]>> {
        const response = await apiClient.post('/task/entitytask', request);
        return response.data.data;
    }

    static async archivTask(id): Promise<any> {
        const response = await apiClient.delete('/task/task/' + id);
        //store.dispatch("kfz/outsourcingUpdate")
        return response.data;
    }

    static async archivEntityTaskTemplate(id): Promise<any> {
        const response = await apiClient.delete('/task/entitytasktemplate/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.entityTaskTemplateUpdate, response.data.data));
        return response.data;
    }

    static async archivEntityTaskTemplateObject(id): Promise<any> {
        const response = await apiClient.delete('/task/entitytasktemplateobject/' + id);
        //store.dispatch("kfz/outsourcingUpdate")
        return response.data;
    }

    static async getStatus(): Promise<TaskStatusDTO[]> {
        const response = await apiClient.get('/task/taskstatus');
        return response.data.data;
    }


    static async getChats(taskId: number): Promise<TaskChatDTO[]> {
        const response = await apiClient.get('/task/taskchat/' + taskId);
        return response.data.data;
    }

    static async putChat(taskId: number, q: TaskChatDTO): Promise<TaskChatDTO> {
        q.taskId = taskId;

        const response = await apiClient.put('/task/taskchat', q);
        return response.data;
    }

    static async getTree(taskId: number): Promise<OrgUnitTreeDTO[]> {
        const response = await apiClient.get('/task/tasktree/' + taskId);
        return response.data.data;
    }

    static async getSubTasks(taskId: number): Promise<any> {
        const response = await apiClient.get('/task/tasksubtask/' + taskId);
        return response.data;
    }

    static async getTaskForms(request: TaskFormRequestDTO): Promise<any> {
        const response = await apiClient.post('/task/taskform/', request);
        return response.data;
    }

    static async getSubReferences(taskId: number): Promise<TaskDTO[]> {
        const response = await apiClient.get('/task/tasktemplate/referring/' + taskId);
        return response.data.data;
    }


    static async getNotizen(taskId: number): Promise<TaskNotizDTO[]> {
        const response = await apiClient.get('/task/tasknotiz/' + taskId);
        return response.data.data;
    }

    static async putNotiz(taskId: number, q: TaskNotizDTO): Promise<TaskNotizDTO> {
        q.taskId = taskId;

        const response = await apiClient.put('/task/tasknotiz', q);
        return response.data;
    }


    static async getReports(taskId: number): Promise<TaskReportMapDTO[]> {
        const response = await apiClient.get('/task/taskreportmap/task/' + taskId);
        return response.data.data;
    }

    static async putReport(taskId: number, q: TaskReportMapDTO): Promise<ApiResponse<TaskReportMapDTO>> {
        const response = await apiClient.put('/task/taskreportmap/' + taskId, q);
        return response.data;
    }

    static async removeReport(mapId): Promise<any> {
        const response = await apiClient.delete('/task/taskreportmap/' + mapId);
        return response.data;
    }

    static async stornoTask(dto: TaskStornoDTO): Promise<ApiResponse<TaskDTO>> {
        const response = await apiClient.put('/task/taskstorno/' + dto.id + '/', dto);
        return new ApiResponse(response);
    }


    static async postCheckinTask(dto: EntityTtoCheckinTaskDTO): Promise<ApiResponse<TaskDTO>> {
        const response = await apiClient.post('/task/entitytasktemplateobject/', dto);
        return new ApiResponse(response);
    }
}
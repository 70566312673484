import {
    AmpelDTO,
    ConcentrationDTO,
    DesiAdviceDTO,
    DesiDTO,
    DesiInheritanceDTO,
    DesiRequestDTO,
    DesiRoutineDTO,
    DiseaseDTO,
    DisinfectantConcentrationTimeTripleDTO,
    DisinfectantDTO,
    EinwirkzeitDTO,
    KfzKennungDTO,
    KfzResponseDTO,
    MaterialDTO,
    MedProduktDTO,
    OrgUnitDTO,
    ProtectionDTO,
    ScopeDTO,
    TaskDTO,
    TypeDTO,
    UserResponseDTO
} from "@/model/dto";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import {AbstractClass, AbstractEntityTaskFormRequest, AbstractListItem} from "@/model/AbstractClasses";

export class Ampel extends AbstractClass implements AmpelDTO {
    archive: boolean | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
}

export class Concentration extends AbstractClass implements ConcentrationDTO {
    archive: boolean | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    quantifier: number | null;
}

export class Type extends AbstractClass implements TypeDTO {
    archive: boolean | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
}

export class Scope extends AbstractClass implements ScopeDTO {
    archive: boolean | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
}

export class Protection extends AbstractClass implements ProtectionDTO {
    archive: boolean | null;
    orgUnit: OrgUnitDTO | null;
    name: string | null;
}

export class Einwirkzeit extends AbstractClass implements EinwirkzeitDTO {
    archive: boolean | null;
    name: string;
    orgUnit: OrgUnitDTO | null;
    quantifier: number | null;
}

export class Disinfectant extends AbstractListItem implements DisinfectantDTO {
    archive: boolean | null;
    comment: string | null;
    manufacturer: string | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
}

export class DctTriple extends AbstractClass implements DisinfectantConcentrationTimeTripleDTO {
    concentration: ConcentrationDTO | null;
    disinfectant: DisinfectantDTO | null;
    einwirkZeit: EinwirkzeitDTO | null;
}

export class Disease extends AbstractListItem implements DiseaseDTO {
    archive: boolean | null;
    comment: string | null;
    transmission: string | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    synonym: string | null;
}

export class DesiRoutine extends AbstractClass implements DesiRoutineDTO {
    alternatives: DisinfectantConcentrationTimeTripleDTO[] = [];
    archive: boolean | null;
    concentration: ConcentrationDTO | null;
    disinfectant: DisinfectantDTO | null;
    einwirkZeit: EinwirkzeitDTO | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    type: TypeDTO | null;
}

export class DesiAdvice extends AbstractListItem implements DesiAdviceDTO {
    ampel: AmpelDTO | null;
    archiv: boolean;
    concentration: ConcentrationDTO | null;
    desinfektorInfo: boolean | null;
    disease: DiseaseDTO | null;
    disinfectant: DisinfectantDTO | null;
    einwirkZeit: EinwirkzeitDTO | null;
    orgUnit: OrgUnitDTO | null;
    protection: ProtectionDTO[] = [];
    routinedesi: boolean | null;
    scope: ScopeDTO[];
    zusatzdaten: string | null;
}

export class Desi extends AbstractClass implements DesiDTO {
    creator: UserResponseDTO;
    static CATEGORY_INFECTION = 0;
    static CATEGORY_ROUTINE = 1;

    category: number = Desi.CATEGORY_INFECTION;
    advice: DesiAdviceDTO | null;
    archive: boolean | null = false;
    comment: string | null;
    concentration: ConcentrationDTO | null;
    date: string | null;
    desinfektorInfo: boolean | null;
    disinfectant: DisinfectantDTO | null;
    erkrankungVorabbekannt: boolean | null;
    kennung: KfzKennungDTO | null;
    kfz: KfzResponseDTO | null;
    medProdukts: MedProduktDTO[] = [];
    opNumber: string | null;
    orgUnit: OrgUnitDTO | null;
    protections: ProtectionDTO[] = [];
    einwirkZeit: EinwirkzeitDTO | null;
    scopes: ScopeDTO[] = [];
    seen: boolean | null;
    sent: boolean | null;
    sentmail: boolean | null;
    task: TaskDTO | null;
    time: string | null;
    type: TypeDTO | null;
    user1: UserResponseDTO | null;
    user1Text: string | null;
    user2: UserResponseDTO | null;
    user2Text: string | null;
    user3: UserResponseDTO | null;
    user3Text: string | null;
    validateTS: string | null;
    validator: UserResponseDTO | null;
    zusatzdaten: string | null;
    desiRoutine: DesiRoutineDTO | null;
    materials: MaterialDTO[] = [];
}

export class DesiInheritance extends AbstractClass implements DesiInheritanceDTO {
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    selection: OrgUnitDTO[] = [];
    forcedBy: OrgUnitDTO[];
}
export class HygieneRequest extends AbstractEntityTaskFormRequest implements DesiRequestDTO {

    constructor() {
        super()
        this.dateFrom = moment().subtract(2, 'weeks').format(moment.HTML5_FMT.DATE);
        this.dateTo = moment().format(moment.HTML5_FMT.DATE);
        this.archive = false;
    }

    onlyUnconfirmed: boolean;
    ouChain: boolean;


    formDataToRequest(self = new HygieneRequest()) {
        self.dateFrom = DateHelper.getSqlDate(this.dateFrom)
        self.dateTo = DateHelper.getSqlDate(this.dateTo)
        self.archive = this.archive


        self.ouId = null

        return self
    }

    taskId: number | null;


}

import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import FormNavComponent from "@/views/form/components/FormNavComponent.vue";
import KfzNavComponent from "@/views/kfz/components/KfzNavComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import {TaskApi} from "@/services/TaskApi";
import UmgzugView from "@/views/shared/UmgzugView.vue";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {UmgzugView, KfzNavComponent, FormNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class KfzView extends Vue {
  showUmzugModal = false
  tableRoute = true
  kfz = null

  clb = (payload: ObjectEvent<any>) => this.archivTask(payload.data)
  clbUmzug = null

  handleRoute(route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'kfz' && this.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Overview)) {
      this.$router.push("/kfz/overview")
    }
  }

  created() {
    this.handleRoute(this.$router.currentRoute)
    bus.$on(Event.taskArchiveRequest, this.clb)


    this.clbUmzug = (payload) => {
      this.kfz = payload
      this.showUmzugModal = true
    }
    bus.$on(Event.kfzUmzugRequest, this.clbUmzug)

  }

  destroyed() {
    bus.$off(Event.taskArchiveRequest, this.clb)
    bus.$off(Event.kfzUmzugRequest, this.clbUmzug)

  }

  archivTask(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie Aufgabe ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      TaskApi.archivEntityTaskTemplate(data.id)
          .then(() => {
            data.archive = !data.archive
            bus.$emit(Event.taskArchived, new ObjectEvent(data.id, data));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}

import {
    AdditionalFieldResultDTO,
    BewertungDTO,
    EintrittswahrscheinlichkeitDTO,
    EntityFileDTO,
    FormDTO,
    FormRowDTO,
    LevelDTO,
    LevelklasseDTO,
    LevelOuDTO,
    OrgUnitDTO,
    PersonenkreisDTO,
    ReportDTO,
    ReportNotizDTO,
    ReportRequestDTO,
    StatusDTO,
    TagDTO,
    TagOuDTO,
    TragweiteDTO,
    UserResponseDTO
} from "@/model/dto";
import moment from "moment/moment";
import {DateHelper} from "@/model/Date";
import {AbstractClass, AbstractEntityTaskFormRequest} from "@/model/AbstractClasses";
import {Module} from "@/model/Constants";


export class Level extends AbstractClass implements LevelDTO {
    archive: boolean | null;
    bewertungType: number | null;
    defaultZustaendig: PersonenkreisDTO | null;
    form: FormDTO | null;
   
    info: PersonenkreisDTO[];
    klasse: LevelklasseDTO | null;
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    parent: LevelDTO | null;
    required: boolean | null;
    status: StatusDTO[];
    tags: TagDTO[];
    vorgabeText: string | null;
    defaultStatus: StatusDTO | null;
    maxDepth: boolean | null;
    public: boolean | null;
    allLevelsRequired = false;

}

export class LevelOu extends AbstractClass implements LevelOuDTO {
    archive: boolean | null;
    email: boolean;
    eskalation: PersonenkreisDTO | null;
   
    level: LevelDTO | null;
    maiLExtern: string | null;
    orgUnit: OrgUnitDTO | null;
    sms: boolean;
    tagsOu: TagOuDTO[];
    vorgabeText: string | null;
    zustaendig: PersonenkreisDTO | null;
    own: boolean;
    required: boolean;
    info: PersonenkreisDTO[];
}

export class Status extends AbstractClass implements StatusDTO {
    archive: boolean | null;
   
    name: string | null;
}

export class Tag extends AbstractClass implements TagDTO {
    archive: boolean | null;
    form: FormDTO | null;
   
    info: PersonenkreisDTO[];
    name: string | null;
    vorgabeText: string | null;
    bewertungType: number | null;
}

export class TagOu extends AbstractClass implements TagOuDTO {
    archive: boolean | null;
    email: boolean;
   
    maiLExtern: string | null;
    own: boolean;
    sms: boolean;
    tag: TagDTO | null;
    vorgabeText: string | null;
    info: PersonenkreisDTO[];
}

export class Report extends AbstractClass implements ReportDTO {
    _oInfoAnPersonenkreise: PersonenkreisDTO[];
    _oInfoAnEmails: string[];
    creator: UserResponseDTO;
    createTS: string;
    sent: boolean;
    notiz: string;
    rows: FormRowDTO[];
    addFields: AdditionalFieldResultDTO[];
    archive: boolean | null;
    bewertung: BewertungDTO | null;
    chance: string | null;
    done: boolean | null;
    ereignisDatum: string | null;
    eskalationsStufe: number | null;
    ewChance: EintrittswahrscheinlichkeitDTO | null;
    ewRisiko: EintrittswahrscheinlichkeitDTO | null;
    files: EntityFileDTO[];
   
    klassifikationChance: string | null;
    klassifikationRisiko: string | null;
    level1: LevelDTO | null;
    level2: LevelDTO | null;
    level3: LevelDTO | null;
    level4: LevelDTO | null;
    level5: LevelDTO | null;
    orgUnit: OrgUnitDTO | null;
    risiko: string | null;
    status: StatusDTO | null;
    tags: TagDTO[];
    text: string | null;
    tragweiteChance: TragweiteDTO | null;
    tragweiteRisiko: TragweiteDTO | null;
    vorgabeText: string | null;
    zustaendigkeit: PersonenkreisDTO | null;
}

export class ReportRequest extends  AbstractEntityTaskFormRequest implements ReportRequestDTO {
    static readonly SPECIAL_KFZ = Module.KFZ;
    static readonly SPECIAL_MEDP = Module.MEDPRODUKT;
    static readonly SPECIAL_MATERIAL = Module.MATERIAL;

    ouChain = true;

    includeDone: boolean;
    includeOpen: boolean;
    levelId: number | null;

    creatorId: number | null;
    userId: number | null;

    classId: number | null;
    onlyPublic = false;


    //FormData
    level: LevelDTO = null;
    doneUndone = 0;
    klasse: LevelklasseDTO = null;

    specialMode = null;

    constructor() {
        super()
        this.dateFrom = moment().subtract(6, 'months').format(moment.HTML5_FMT.DATE);
        this.dateTo = moment().format(moment.HTML5_FMT.DATE);
        this.archive = false;
    }


    formDataToRequest(self = new ReportRequest()) {
        self.levelId = (this.level) ? this.level.id : null
        self.classId = (this.klasse) ? this.klasse.id : null
        self.includeOpen = ([0, 2].includes(this.doneUndone))
        self.includeDone = ([1, 2].includes(this.doneUndone))
        self.dateFrom = DateHelper.getSqlDate(this.dateFrom)
        self.dateTo = DateHelper.getSqlDate(this.dateTo)

        self.ouChain = this.ouChain
        self.archive = this.archive

        self.ouId = null
        self.userId = null
        self.creatorId = null

        return self
    }

    static buildEntityReportRequest(entityId, chain: boolean) {
        const params = new ReportRequest()
        params.dateFrom = null
        params.dateTo = null
        params.ouChain = chain
        params.includeOpen = true
        params.includeDone = false
        params.specialMode = entityId
        return params
    }
}


export class ReportNotiz extends AbstractClass implements ReportNotizDTO {
    createTS: string | null;
    creator: UserResponseDTO | null;
   
    reportId: number | null;
    text: string | null;
}

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";
import {Permission, PermissionModul} from "@/model/dto";

@Component
export default class AdminDashboardHygieneCard extends Vue {
  @Prop() chain!: boolean
  unconfirmed = 0
  kontrolle = 0
  dataKontrolle = null
  dataUnconfirmedHygiene = null
  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    const paramsDesi = {
      ouId: this.$store.getters.getLocation.id,
      onlyUnconfirmed: true,
      ouChain: this.chain,
      countOnly: true
    }
    const paramsHygiene = {
      anyKfz: true,
      hygieneOnly: true,
      statusId: -1,
      ouChain: this.chain,
      countOnly: true,
      ouId: this.$store.getters.getLocation.id,
    }

    this.dataKontrolle = this.desiPermission ? await DashboardAdminApi.getDesiDashboardData(paramsDesi) : [];
    this.kontrolle = this.dataKontrolle.count

    this.dataUnconfirmedHygiene = this.taskPermission ? await DashboardAdminApi.getTaskHygieneData(paramsHygiene) : [];
    this.unconfirmed = this.dataUnconfirmedHygiene.count
    this.loading = false
  }

  routeHygieneKontorlle() {
    this.$router.push("/hygiene/desioverview")
  }

  routeHygieneUeberfaellig() {
    this.$router.push("/kfz/tasklist")

  }

  get taskPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Task, Permission.Overview)
  }

  get desiPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Hygiene, Permission.Overview)
  }

}

import {AbstractClass} from "@/model/AbstractClasses";
import {AuktionDTO, DienstDTO, OrgUnitDTO, SuchenDTO} from "@/model/dto";

export class Auktion extends AbstractClass implements AuktionDTO {
    static readonly MODUS_LINEAR = 0;
    static readonly MODUS_DIREKT = 1;

    static readonly INFORM_CREATOR = 0;
    static readonly INFORM_EVD = 1;

    additionalOus: OrgUnitDTO[] = [];
    archive: boolean;
    datumEnde: string | null;
    datumStart: string | null;
    diva = true;
    inform: number | null = Auktion.INFORM_CREATOR;
    kommentar: string | null;
    modus: number | null = Auktion.MODUS_LINEAR;
    orgUnit: OrgUnitDTO | null;
    portal = true;
    suchen: SuchenDTO[] = [new Suche()];
    warten: number | null = 15;
    zeitEnde: string | null;
    zeitStart: string | null;
    dienst: DienstDTO | null;
    lock: boolean | null;
}

export class Suche extends AbstractClass implements SuchenDTO {
    lock: boolean;
    static readonly STATUS_INACTIVE = 0;
    static readonly STATUS_ACTIVE = 1;
    static readonly STATUS_END = 2;

    anzahl: number | null = 1;
    funde: number | null;
    personenkreis: [] = [];
    qualiJump: boolean | null = false;
    status: number | null = Suche.STATUS_ACTIVE;
}
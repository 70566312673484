
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, ModulPermission, OrgUnitMerkmalDTO, PermissionDTO, PermissionGroupDTO, PermissionTextDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import {PermissionGroup} from "@/model/Auth";
import {AuthApi} from "@/services/AuthApi";
import Multiselect from "@/libs/multiselect";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import OrgUnitListView from "@/views/ou/OrgUnitListView.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {OrgUnitListView, SimpletableButtonHeader, Multiselect}
})
export default class PermissionGroupView extends mixins<SimpleViewMixin<PermissionGroupDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: PermissionGroupDTO = new PermissionGroup()
  error: GenericError = new GenericError()

  loading = true

  permissionOption: PermissionDTO[] = []
  modulPermissions: ModulPermission[] = []
  ouMerkmale: OrgUnitMerkmalDTO[] = []

  async created() {
    await this.reload()
  }

  async reload() {
    this.loading = true

    this.permissionOption = await AuthApi.getAllPermissions()
    this.modulPermissions = this.$store.getters.getConstants.permissions;

    await this.init(PermissionGroup,
        AuthApi.getPermissiongroups,
        (a) => AuthApi.putPermissiongroup(a),
        (b) => AuthApi.removePermissiongroup(b),
    )

    this.ouMerkmale = await OrgUnitApi.getMerkmale()
    this.$root.$emit('loadingDone')
    this.loading = false
  }

  checkPermission(q: PermissionGroupDTO, modul: ModulPermission, perm: PermissionTextDTO) {
    return q.permissions.some(p => p?.permission === perm.id && p.modul === modul.identifier)
  }

  change(e, q: PermissionGroupDTO, modul: ModulPermission, perm: PermissionTextDTO) {
    if (e) {
      const permOption = this.permissionOption.find(p => p.modul === modul.identifier && p.permission === perm.id)
      q.permissions.push(permOption)
    } else {
      q.permissions = q.permissions.filter(p => !(p.permission === perm.id && p.modul === modul.identifier))
    }

    AuthApi.putPermissiongroup(q).catch(e => {
      this.reload()
      this.$alert("Es ist ein Fehler aufgetreten!", "Fehler", "error")
    })
  }

  get archivTxt() {
    return "Löschen"
  }
}

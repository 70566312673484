
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import moment from "moment/moment";
import {EntityStandortDTO, EwoDTO, MedProduktDTO} from "@/model/dto";
import {ObjectEvent} from "@/model/AbstractClasses";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {notAllowedText as txt} from "@/model/MedProdukt";
import DateForm from "@/components/DateForm.vue";
import LocationChooser from "@/components/LocationChooser.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {EwoEdit} from "@/model/Ewmpg";


@Component({components: {LabelComponent, DateForm, ListItemComponent, LocationChooser, NumberForm, Multiselect}})
export default class MedProduktDataView extends mixins<GenericMixin<MedProduktDTO, MedProduktDTO>>(GenericMixin) {
  @Prop() value: MedProduktDTO;
  @Prop() error: MedProduktDTO;
  data: MedProduktDTO = this.$props.value;

  locationOpts: EntityStandortDTO[] = []
  ewoOpts: EwoDTO[] = []
  ewoEdit = EwoEdit
  differentChildsText = "siehe Einzelgeräte"

  noCommercialUse = txt


  async created() {
    this.ewoOpts = await this.$store.dispatch("medprodukt/fetchEwos")
    this.ewoOpts = this.ewoOpts.filter(ewo => ewo.kombiTyp != 2)

    this.locationOpts = await this.$store.dispatch('medprodukt/fetchStandorte')


    this.$root.$emit('loadingDone')

    bus.$on(Event.medProduktChildDataChange, (payload: ObjectEvent<string>) => {
      if (payload.id == this.data.id) {
        let initVal = this.data.childs[0][payload.data]
        this.data[payload.data] = initVal
        this.data.differentChilds = this.data.differentChilds.filter(e => e != payload.data)

        this.data.childs.forEach((e, idx) => {
          if (initVal != e[payload.data]) {
            this.data.differentChilds.push(payload.data)
            this.data.differentChilds = [...new Set(this.data.differentChilds)];
            this.data[payload.data] = null
          }
        })
      }
    })
  }

  destroyed() {
    bus.$off(Event.medProduktChildDataChange)
  }

  ewoChanged() {
    if (this.data.ewo && this.data.ewo.softwareversionen.length > 0) {
      this.data.softwareVersion = this.data.ewo.softwareversionen[0]
    } else {
      this.data.softwareVersion = null
    }

    if (this.data.ewo && this.data.ewo.kombi) {
      this.data.holder = true
      this.data.serienNummer = ""
      this.data.udi = ""
    } else {
      this.data.holder = false
    }
  }

  inbetriebnahmeChanged() {
    this.fieldChanged('inbetriebnahmeDatum')
    bus.$emit(Event.medProduktInbetriebnahmeChange, new ObjectEvent(this.value.id, this.data));

    if (this.data.inbetriebnahmeDatum && this.data.ewo && this.data.ewo.maxNutzung) {
      var fm = moment(this.data.inbetriebnahmeDatum).add(this.data.ewo.maxNutzung, 'M');
      this.data.ausserbetriebnahmeDatum = fm.format("YYYY-MM-DD")
    } else {
      this.data.ausserbetriebnahmeDatum = null
    }
  }

  fieldChanged(field) {
    if (this.data.childs) {
      this.data.childs.forEach(e => e[field] = this.data[field])
    }
    //bus.$emit(Event.medProduktDataChange, new ObjectEvent(this.value.id, this.data));
  }

  get verwaltungsStandortOpts() {
    return this.locationOpts.filter(location => location.orgUnit != null && !location.customLocation).map(location => location.orgUnit)
  }

  get primStandortOpts() {
    return this.locationOpts.filter(location => !location.abstract)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

}


import {Component} from "vue-property-decorator";
import GenericMixin from "@/views/GenericMixin.vue";
import {ResponseDTO} from "@/model/dto";
import {debounce} from '@/scripts/scripts'
import {_} from 'vue-underscore';

@Component
export default class SimpleViewMixin<T = any, E = any> extends GenericMixin<T, E> {
  objects: T[] = []
  showModal = false
  newT: T
  archivSubText = ''
  archivText = 'Archivieren'
  reactiveText = 'Reaktivieren'

  selectedItem : T = null
  search = ""
  loadingDone = false
  searchInput = ""
  debounceFn = null
  // eslint-disable-next-line @typescript-eslint/ban-types
  getPromise: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  putPromise: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  removePromise: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  upsertCallback: Function

  getParam = undefined


  // eslint-disable-next-line @typescript-eslint/ban-types
  async init(TCreator: new() => T, func: Function, func2: Function, func3: Function, getParam = undefined, upsertCallBack: Function = undefined) {
    this.initFilter()

    if (TCreator) this.newT = new TCreator()

    if (getParam != undefined) {
      this.objects = await func(getParam);
    } else {
      this.objects = await func();
    }

    this.loadingDone = true

    this.putPromise = func2
    this.removePromise = func3
    this.upsertCallback = upsertCallBack
  }

  initFilter() {
    this.debounceFn = debounce(() => {
      this.search = this.searchInput
    }, 300)
  }

  editObject(dto: T) {
    this.data = structuredClone(dto)
    this.createErrorDto()
    this.showModal = true
  }

  newObject() {
    this.selectedItem = null
    this.data = structuredClone(this.newT);
    this.createErrorDto()
    this.showModal = true
  }

  createErrorDto() {
    Object.entries(structuredClone(this.newT)).forEach(
        ([key, value]) => this.error[key] = ''
    );
  }


  saveObject() {
    const p = this.putPromise(this.data);
    const created = (this.data && !this.data.id)

    this.handleApiRequest(p, true, true).then((data: ResponseDTO) => {
      if (data.data != undefined) {
        this.showModal = false

        this.objects = this.objects.filter((tmp) => {
          return tmp['id'] != data.data['id']
        })
        this.objects.push(data.data)
      }

      if (this.upsertCallback !== undefined) {
        this.upsertCallback(data, created)
      }
    });
  }

  removeObject(uQ: T) {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?' + (this.archivSubText ? ' ' + this.archivSubText : ''), '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      this.removePromise(uQ['id'])
          .then(() => {
            this.data['archive'] = !this.data['archive']
            this.objects = this.objects.filter((tmp) => {
              return tmp['id'] != uQ['id']
            })
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " trat ein Fehler auf!");
          })
    });
  }


  onFilter(e) {
    this.searchInput = e.target.value
    this.debounceFn()
  }

  filterIt() {
    return this.objects.filter((obj) => {
      return Object.keys(obj).some((key) => {
        if (_.isBoolean(obj[key])) return this.search.toLowerCase() === 'x' ? obj[key] : false

        if (this.search.toLowerCase() === 'x') return false

        return (obj[key] !== null && obj[key] !== undefined)
            ? ((typeof obj[key] === 'string' || obj[key] instanceof String))
                ? obj[key].toLowerCase().includes(this.search.toLowerCase()) : JSON.stringify(obj[key]).toLowerCase().includes(this.search.toLowerCase())
            : false
      })
    });
  }

  sort(objects) {
    return objects
  }

  get getObjects() {
    if (this.search.length > 0) {
      return this.sort(this.filterIt())
    }
    return this.sort(this.objects)
  }

  get archivTxt() {
    if (this.data == undefined || this.data['archive'] === undefined) return this.archivText
    return this.data['archive'] ? this.reactiveText :this.archivText
  }

  onRowContextMenu(event) {
    this.$refs.cm.show(event.originalEvent);
  }

  exportCsv() {
    this.$refs.dt.exportCSV();
  }

}


import UserRegisterService from '@/services/UserRegisterService'
import {Component, Vue} from "vue-property-decorator";
import {func} from '@/scripts/scripts'
import store from '@/store'
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UserProfile} from "@/model/User";
import {UserPinDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";
import jquery from "jquery";

@Component({
  components: {},
})
export default class UserChangePinView extends mixins<GenericMixin<UserPinDTO, UserPinDTO>>(GenericMixin) {
  data: UserPinDTO = {
    pin: '',
    pinConfirm: '',
    id: store.getters.getUser.id
  }
  error: UserPinDTO = {
    pin: '',
    pinConfirm: '',
    id: 0
  }


  onSubmit() {
    const p = UsersApi.updatePin(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true);
  }
}

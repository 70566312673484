
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {_} from 'vue-underscore';
import {HistoryEvent} from "@/model/Shared";
import NavMixin from "@/views/NavMixin.vue";
import {mixins} from "vue-class-component";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DateForm, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class KfzNavComponent extends mixins(NavMixin) {
  routeName = null
  onChangeDebounced = null
  fileCategories = []
  dateTypes = []

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['kfzlist', 'kfzdatelist', 'kfzfilelist', 'kfztasktemplatelist', 'kfztasklist', 'kfzimport'],
        this.navBar)

    this.fillNavbar(
        ['kfzfilecategory', 'kfzfilecategorycatalog', 'kfzdatesettings',
          'kfzdatetypcatalog', 'kfzstandorte', 'kfzkennung', 'kfzcategory', 'kfzformlist',
          'kfzausbauer', 'kfzherstellermodell', 'kfzqualisimport'], this.navBarSettings)

    this.handleRoute()
    this.fileCategories = await this.$store.dispatch("kfz/fetchDateiKategorienOu")
    this.dateTypes = await this.$store.dispatch("kfz/fetchDateTypesOu")

    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.entityTaskFilterChanged)
    }, 1500)

  }

  get fileCategoryOpts() {
    let arr = this.fileCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    return [...new Map(arr.map((item) => [item["id"], item])).values()];
  }

  get fileCategory() {
    return this.$store.state.kfz.filter.fileCategory
  }

  set fileCategory(val) {
    this.$store.state.kfz.filter.fileCategory = val
    bus.$emit(Event.kfzFileCategoryFilter, val)
  }


  get dateTyp() {
    return this.$store.state.kfz.filter.dateType
  }

  set dateTyp(val) {
    this.$store.state.kfz.filter.dateType = val
    bus.$emit(Event.kfzDateCategoryFilter, val)
  }

  get isFormTabActive(): boolean {
    const regexFormTab = /^\/.*\/edit\/.*\/9(?:[/?#]|$)/;
    return regexFormTab.test(this.$route.path);
  }

  get isTaskTabActive(): boolean {
    const regexTaskTab = /^\/.*\/edit\/.*\/8(?:[/?#]|$)/;
    return regexTaskTab.test(this.$route.path);
  }

  get isFahrtenbuch(): boolean {
    const regexTaskTab = /^\/.*\/edit\/.*\/10(?:[/?#]|$)/;
    return regexTaskTab.test(this.$route.path);
  }

  get isDateFormActive(): boolean {
    return this.routeName.includes('tasklist') || this.isFormTabActive || this.isTaskTabActive || this.isFahrtenbuch
  }

  handleRoute() {
    this.routeName = this.$route.name
  }

  get datefrom() {
    return this.$store.state.task.entityTaskRequestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.task.entityTaskRequestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.task.entityTaskRequestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.task.entityTaskRequestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get modul() {
    return this.$store.state.task.entityTaskRequestFilter.modulId
  }

  set modul(val) {
    this.$store.state.task.entityTaskRequestFilter.modulId = val
    this.onChangeDebounced()
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/kfz/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Settings, Permission.History)
  }
}


import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {UserFileCategoryDTO} from "@/model/dto";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import UserFileView from "@/views/user/UserFileView.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import TaskModal from "@/views/task/components/TaskModal.vue";


@Component(
    {
      components: {TaskModal, ListItemComponent, UserFileView, PersonenkreisTag},
    }
)
export default class UserFileList extends Vue {
  fileCategories: UserFileCategoryDTO[] = []

  showModal = false
  showAllTags = false
  maxTags: number;


  clb = null;
  selectedElem = null
  key = 0

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']
    this.selectedElem = this.$store.state.user.filter.fileCategory
    this.clb = (fileCategory) => {
      this.selectedElem = fileCategory
      this.key++
    }

    this.fileCategories = await this.$store.dispatch("user/fetchDateiKategorienOu")
    bus.$on(Event.userFileCategoryFilter, this.clb)

  }

  destroyed() {
    bus.$off(Event.userFileCategoryFilter, this.clb)
  }

  get personenkreise() {
    return (this.selectedElem) ? this.fileCategories.filter((ufk) => ufk.fileCategory.id == this.selectedElem.id).map((ufk) => ufk.personenkeis).flat() : []
  }

  openModal() {
    this.showModal = true
  }
}

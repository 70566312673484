import FormTables from "@/views/form/FormTables.vue";
import FormView from "@/views/form/FormView.vue";
import FormVersionCreateView from "@/views/form/FormVersionCreateView.vue";
import FormVersionEditView from "@/views/form/FormVersionEditView.vue";
import FormCreateView from "@/views/form/FormCreateView.vue";
import DiensteView from "@/views/ou/DiensteView.vue";
import {Permission, PermissionModul} from "@/model/dto";
import EvdView from "@/views/ou/EvdView.vue";


export const formRoutes = [
    {
        path: 'form',
        name: 'form',
        redirect: '/form/formtable',
        component: FormView,
        children: [
            {
                path: 'formtable',
                name: 'formtable',
                component: FormTables,
                meta: {
                    datatable: true,
                    title: 'Formulare',
                    loading: true,
                    activeRoutes: ['formtable', 'formcreate', 'formversionedit']
                }
            },
            {
                path: 'abfragezeiten',
                name: 'abfragezeiten',
                component: FormTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Abfragezeiten',
                }
            },
            {
                path: 'dienste',
                name: 'dienste',
                component: FormTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Dienste',
                }
            },
            {
                path: 'evd',
                name: 'evd',
                component: EvdView,
                meta: {
                    loading: true,
                    title: 'EVD',
                }
            },
            {
                path: 'create/:formVersion?',
                name: 'formcreate',
                components: {
                    default: FormTables,
                    CRUD: FormCreateView
                },
                props: {
                    default: false, CRUD: true
                },
            },
            {
                path: ':id/version',
                name: 'formversiontable',
                component: FormTables,
                meta: {
                    datatable: true
                },
                props: true
            },
            {
                path: ':form/version/create/:formVersion?',
                name: 'formversioncreate',
                components: {
                    default: FormTables,
                    CRUD: FormVersionCreateView
                },
                props: {
                    default: false, CRUD: true
                },
            },
            {
                path: ':form/version/edit/:id',
                name: 'formversionedit',
                components: {
                    default: FormTables,
                    CRUD: FormVersionEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true
                }
            },
        ],
        meta: {
            permission: [PermissionModul.Stammdaten, Permission.CRUD]
        }
    }
]

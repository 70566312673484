import FobiTables from "@/views/fobi/FobiTables.vue";
import FobiEditView from "@/views/fobi/FobiEditView.vue";
import FobiCreateView from "@/views/fobi/FobiCreateView.vue";
import FobiView from "@/views/fobi/FobiView.vue";
import FobiKombiThemaEditView from "@/views/fobi/FobiKombiThemaEditView.vue";
import FobiKombiThemaCreateView from "@/views/fobi/FobiKombiThemaCreateView.vue";
import FobiThemaView from "@/views/fobi/FobiThemaView.vue";
import FobiKlasseView from "@/views/fobi/FobiKlasseView.vue";
import FobiZeitraumEditView from "@/views/fobi/FobiZeitraumEditView.vue";
import FobiZeitraumCreateView from "@/views/fobi/FobiZeitraumCreateView.vue";
import {Permission, PermissionModul} from "@/model/dto";
import CampusOverviewTable from "@/views/fobi/CampusOverviewTable.vue";


export const fobiRoutes = [
    {
        path: 'fobi',
        name: 'fobi',
        component: FobiView,
        children: [
            {
                path: 'themen',
                name: 'fobithemen',
                component: FobiThemaView,
                meta: {
                    loading: true,
                    title: 'Themenkatalog',
                    permission: [PermissionModul.Fobi_Settings, Permission.Themen]
                }
            },
            {
                path: 'klassen',
                name: 'fobiklassen',
                component: FobiKlasseView,
                meta: {
                    loading: true,
                    title: 'Fortbildungsarten-Katalog',
                    permission: [PermissionModul.Fobi_Settings, Permission.Arten]
                }
            },
            {
                path: 'fobitable',
                name: 'fobitable',
                component: FobiTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Übersicht',
                    permission: [PermissionModul.Fobi, Permission.Overview],
                    activeRoutes: ['fobitable', 'fobicreate', 'fobiedit']
                }
            },
            {
                path: 'fobimyfobi',
                name: 'fobimyfobi',
                component: FobiTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Meine Fortbildungen',
                    permission: [PermissionModul.Fobi, Permission.Access_Own],
                    activeRoutes: ['fobiMyCreate', 'fobiMyEdit']
                }
            },
            {
                path: 'create',
                name: 'fobicreate',
                components: {
                    default: FobiTables,
                    CRUD: FobiCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Fobi, Permission.Create]
                }
            },
            {
                path: 'edit/:id',
                name: 'fobiedit',
                components: {
                    default: FobiTables,
                    CRUD: FobiEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Fobi, Permission.Overview]
                }
            },
            {
                path: 'mycreate',
                name: 'fobiMyCreate',
                components: {
                    default: FobiTables,
                    CRUD: FobiCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Fobi, Permission.Access_Own]
                }
            },
            {
                path: 'myedit/:id',
                name: 'fobiMyEdit',
                components: {
                    default: FobiTables,
                    CRUD: FobiEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Fobi, Permission.Access_Own]
                }
            },
            {
                path: 'kombithema',
                name: 'fobikombithematable',
                component: FobiTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Kombithemen',
                    permission: [PermissionModul.Fobi_Settings, Permission.Kombithemen]
                }
            },
            {
                path: 'kombithema/create',
                name: 'kombithemacreate',
                components: {
                    default: FobiTables,
                    CRUD: FobiKombiThemaCreateView
                },
                meta: {
                    permission: [PermissionModul.Fobi_Settings, Permission.Kombithemen]
                }
            },
            {
                path: 'kombithema/edit/:id',
                name: 'kombithemaedit',
                components: {
                    default: FobiTables,
                    CRUD: FobiKombiThemaEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Fobi_Settings, Permission.Kombithemen]
                }
            },
            {
                path: 'zeitraum',
                name: 'fobizeitraumtable',
                component: FobiTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Zeiträume',
                    permission: [PermissionModul.Fobi_Settings, Permission.Zeitraum]
                }
            },
            {
                path: 'zeitraum/create',
                name: 'zeitraumcreate',
                components: {
                    default: FobiTables,
                    CRUD: FobiZeitraumCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Fobi_Settings, Permission.Zeitraum]
                }
            },
            {
                path: 'zeitraum/edit/:id',
                name: 'zeitraumedit',
                components: {
                    default: FobiTables,
                    CRUD: FobiZeitraumEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Fobi_Settings, Permission.Zeitraum]
                }
            },
            {
                path: 'zeitraumkummulativ',
                name: 'zeitraumkummulativ',
                component: FobiTables,
                meta: {
                    loading: true,
                    datatable: true,
                    title: 'Kummulativ-Zeiträume',
                    permission: [PermissionModul.Fobi, Permission.Overview_Chain]
                }
            },
            {
                path: 'campus',
                name: 'fobicampusoverview',
                component: CampusOverviewTable,
                meta: {
                    loading: true,
                    title: 'Campus-Übersicht',
                    permission: [PermissionModul.Fobi, Permission.Overview]
                }
            },
        ]
    }
]

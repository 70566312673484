import {apiClient} from './AbstractHTTPService'
import {ApiResponse} from "@/model/AbstractClasses";
import {AuktionDTO, OrgUnitDTO, OrgUnitTreeDTO, SuchenPersonDTO} from "@/model/dto";


export abstract class DivaApi {

    static async putAuktion(Auktion: AuktionDTO): Promise<ApiResponse<AuktionDTO>> {
        const response = await apiClient.put('/diva/auktion/' + Auktion.id + '/', Auktion);
        return new ApiResponse(response);
    }

    static async getAuktion(id): Promise<ApiResponse<AuktionDTO>> {
        const response = await apiClient.get('/diva/auktion/' + id);
        return new ApiResponse(response);
    }

    static async removeAuktion(id): Promise<any> {
        const response = await apiClient.delete('/diva/auktion/' + id);
        return response.data;
    }

    static async getDivaOrgUnits(): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('auth/oulist/diva');
        return response.data.data;
    }


    static async getSuchenUser(suchenId): Promise<SuchenPersonDTO[]> {
        const response = await apiClient.get<OrgUnitTreeDTO>('/diva/personen/suche/' + suchenId);
        return response.data.data;
    }


}
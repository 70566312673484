
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event} from "@/model/Constants";
import {bus} from "@/main";
import {ObjectEvent} from "@/model/AbstractClasses";
import '@/scripts/dataTables_German'
import {DateHelper} from "@/model/Date";
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {},
      watch: {
        '$route'(val: Route) {
          if (val.name.includes("auktionlist")) {
            this.table.fixedHeader.adjust();
            this.table.fixedHeader.enable()
            this.$root.$emit('loadingDone')
          } else {
            this.table.fixedHeader.disable()
          }
        },
        '$route.query.archive'(val) {
          if (this.$route.query.archive != this.archive) this.init()

        }
      }
    }
)
export default class AuktionList extends mixins<GenericMixin<null, null>>(GenericMixin) {
  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  initialized = false



  getUrl() {
    return '/diva/auktion/' + ((this.archive) ? 'archive/' : 'noarchive/');
  }

  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    this.archive = this.$route.query.archive !== undefined

    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    this.registerEvents([Event.divaArchived, Event.divaChanged, Event.divaCreated]);

    var p = jquery(".dt-button-bar").last();
    const dateHelper = DateHelper


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'GET'
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: "id",
      columns: [
        {
          title: 'ID',
          class: '',
          data: 'id',
          visible: true
        },
        {
          title: 'Schichtbeginn',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.datumStart)
          },
          visible: true
        },
        {
          title: 'Uhrzeit Beginn',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseTime(data.zeitStart)
          },
          visible: true
        },
        {
          title: 'Schichtende',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseDate(data.datumEnde)
          },
          visible: true
        },
        {
          title: 'Uhrzeit Ende',
          data: null,
          render: function (data, type, row) {
            return dateHelper.parseTime(data.zeitEnde)
          },
          visible: true
        },
        {
          title: 'Dienst',
          class: '',
          data: 'dienst.name',
          visible: true
        }
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(myButtons)
    })
    self.table = table
    func.datatableInitComplete(table)
    self.initContextMenu(contextMenuButtons)

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Diva, Permission.Access,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Diva, Permission.Overview,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Fobi, Permission.Archive,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )

    this.addButton(
        PermissionModul.Diva, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/diva/auktion', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/diva/auktion', tr.attr('id')))
          }
        }, contextMenuButtons)

  }

  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/diva/auktion/create/", query: queryParam})
  }


  edit(id) {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/diva/auktion/edit/" + id, query: queryParam})
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({name: "auktionlist", query: queryParam})
  }

  destroyed() {
    this.table.fixedHeader.disable()
    this.unregisterEvents()
  }


  archiv(data) {
    bus.$emit(Event.divaArchiveRequest, new ObjectEvent(data.id, data));
  }

}

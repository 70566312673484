
import {Component, Vue, Watch} from "vue-property-decorator";
import {UserProfile} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import DateForm from "@/components/DateForm.vue";

const DataProps = Vue.extend({
  props: {
    value: UserProfile,
    error: UserProfile
  }
})

@Component({
  components: {DateForm}
})
export default class UserProfilePersonView extends mixins<GenericMixin<UserProfile, UserProfile>>(GenericMixin, DataProps) {
  data = this.$props.value;
  mobileDbValue = JSON.parse(JSON.stringify(this.$props.value.mobile));

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }
}

import { render, staticRenderFns } from "./UserFileEditModal.vue?vue&type=template&id=3039f421&scoped=true&"
import script from "./UserFileEditModal.vue?vue&type=script&lang=ts&"
export * from "./UserFileEditModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3039f421",
  null
  
)

export default component.exports
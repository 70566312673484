
import {Component, Prop} from "vue-property-decorator";
import UserProfilePersonView from "@/views/profile/UserProfilePersonView.vue";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UserProfile} from "@/model/User";
import {UsersApi} from "@/services/UserApi";
import UserChangePinView from "@/views/profile/UserChangePinView.vue";
import jquery from "jquery";
import QrcodeVue from 'qrcode.vue'
import UserProfileDressSizeView from "@/views/profile/UserProfileDressSizeView.vue";
import {ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import FileUpload from "@/components/FileUpload.vue";
import UserEinweisungUserView from "@/views/user/UserEinweisungUserView.vue";
import EinweisungEinweiserView from "@/views/einweisung/EinweisungEinweiserView.vue";
import UserMaterialAusgabeUserView from "@/views/user/UserMaterialAusgabeUserView.vue";
import UserFileUserView from "@/views/user/UserFileUserView.vue";
import {FileApi} from "@/services/FileApi";
import {Module} from "@/model/Constants";
import {Permission, PermissionModul} from "@/model/dto";
import FobiUserOverviewTable from "@/views/fobi/FobiUserOverviewTable.vue";
import CampusUserOverviewTable from "@/views/fobi/CampusUserOverviewTable.vue";


@Component({
  computed: {
    Module() {
      return Module
    },
    FileApi() {
      return FileApi
    }
  },
  components: {
    CampusUserOverviewTable,
    FobiUserOverviewTable,
    UserFileUserView,
    UserMaterialAusgabeUserView,
    EinweisungEinweiserView,
    UserEinweisungUserView,
    UserProfilePersonView, UserProfileDressSizeView, UserChangePinView, QrcodeVue, FileUpload
  }
})
export default class UserProfileView extends mixins<GenericMixin<UserProfile, UserProfile>>(GenericMixin) {
  @Prop({default: false}) force

  data = new UserProfile();
  error = new UserProfile();
  showTabs = false
  tabIndex = 0
  errorTabs = []
  uploadKey = 0
  basePath = process.env.VUE_APP_REWIS
  showProfilePhotoUploadModal = false
  existingProfilePhoto = null


  created() {
    const p = UsersApi.getProfile();
    this.handleApiRequest(p)

    if (this.force) {
      this.$alert('Bitte prüfen Sie Ihre Daten und bestätigen diese mit dem Speichern-Button', 'Daten prüfen', 'info');
    }

    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
      if (this.data.profilePhoto) {
        this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
      }

    })
    this.uploadKey += 1
  }

  downloadQrCode = function () {
    let wrapper = document.getElementById("myQrCode");
    let canvas = wrapper.firstChild

    let link = document.createElement('a');
    link.download = 'qrcode.png';
    link.href = canvas.toDataURL("image/png");
    link.click();
  }

  editProfilePhoto() {
    this.showProfilePhotoUploadModal = true
  }

  profilePhotoFileUploadSuccess(d) {
    let file = new RewisFile(d);

    UsersApi.updateUserPhoto(this.data.id, file)
        .then((photo) => {
          this.showProfilePhotoUploadModal = false
          this.data.profilePhoto = photo
          this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
        })
        .catch((e) => {
          this.$alert("Beim Upload des Profilbiles trat ein Fehler auf!");
        });
  }

  profilePhotoFileDelete(d) {
    UsersApi.removeUserPhoto(this.data.id)
        .then((photo) => {
          this.data.profilePhoto = null
          this.existingProfilePhoto = null
        })
        .catch((e) => {
          this.$alert("Beim Löschen des Profilbiles trat ein Fehler auf!");
        });
  }

  onSubmit() {
    this.errorTabs = []
    const p = UsersApi.updateProfile(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then(() => {
      jquery("#usertabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );
    })
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.MYPROFILE, Permission.Access_Own)
  }
  get pwPermission() {
    return this.$store.getters.hasPermission(PermissionModul.MYPASSWORD, Permission.Access_Own)
  }

}

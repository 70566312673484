import { render, staticRenderFns } from "./UserDashboardEvdCard.vue?vue&type=template&id=9bf25d80&scoped=true&"
import script from "./UserDashboardEvdCard.vue?vue&type=script&lang=ts&"
export * from "./UserDashboardEvdCard.vue?vue&type=script&lang=ts&"
import style0 from "./UserDashboardEvdCard.vue?vue&type=style&index=0&id=9bf25d80&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9bf25d80",
  null
  
)

export default component.exports
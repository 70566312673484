
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {DashboardAdminApi} from "@/services/DashboardAdminApi";

@Component
export default class AdminDashboardTaskCard extends Vue {
  @Prop() chain!: boolean
  openTasks = 0
  openTasksMassnahme = 0
  openInfos = 0
  dataTasks = null
  loading = true

  created() {
    this.init()
  }

  @Watch('chain')
  async onChainChange() {
    this.loading = true
    await this.init();
  }

  async init() {
    const paramsTasks = {
      ouId: this.$store.getters.getLocation.id,
      statusId: -1,
      groupCount: true,
      ouChain: this.chain
    }

    this.dataTasks = await DashboardAdminApi.getDashboardTaskData(paramsTasks);
    this.openTasks = this.dataTasks.filter(d => d.name === "1").map(d => d.count).reduce((a, b) => a + b, 0)
    this.openTasksMassnahme = this.dataTasks.filter(d => d.name === "2").map(d => d.count).reduce((a, b) => a + b, 0)
    this.openInfos = this.dataTasks.filter(d => d.name === "4").map(d => d.count).reduce((a, b) => a + b, 0)
    this.loading = false
  }

  routeTasks() {
    this.$router.push("/task/tasktable")
  }
}

import {apiClient} from './AbstractHTTPService'
import {AbfragezeitenDTO, DienstDTO, OrgUnitDTO, OrgUnitEvdDTO, OrgUnitEvdResponseDTO, OrgUnitMerkmalDTO, OrgUnitTreeDTO, OrgUnitTypDTO} from "@/model/dto";
import store from "@/store";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";


export abstract class OrgUnitApi {

    static async getOrgUnits(param = ''): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get('/ou/orgunit/' + param);
        return response.data.data;
    }
    static async getOrgUnitsExtended(param = ''): Promise<OrgUnitDTO[]> {
        const response = await apiClient.get('/ou/orgunitextended/' + param);
        return response.data.data;
    }
    static async putOrgUnit(q: OrgUnitDTO): Promise<OrgUnitDTO> {
        const response = await apiClient.put('/ou/orgunitextended', q);
        return response.data;
    }

    
    
    static async getOuPermissions(user: number, ou: number): Promise<OrgUnitTreeDTO> {
        const response = await apiClient.get<OrgUnitTreeDTO>('ou/oupermission/' + user + "/" + ou);
        return response.data;
    }


    static async getDienste(param = ''): Promise<DienstDTO[]> {
        const response = await apiClient.get('/ou/dienst/' + param);
        return response.data.data;
    }

    static async putDienst(q: DienstDTO): Promise<ApiResponse<DienstDTO>> {
        const response = await apiClient.put('/ou/dienst', q);
        store.dispatch("crudEvent", new CrudEvent(Event.dienstUpdate, response.data.data));
        return response.data;
    }

    static async removeDienst(id: number): Promise<DienstDTO> {
        const response = await apiClient.delete('/ou/dienst' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.dienstUpdate, response.data.data, id));
        return response.data;
    }


    


    static async getAbfragezeiten(param = ''): Promise<AbfragezeitenDTO[]> {
        const response = await apiClient.get('/ou/abfragezeiten/' + param);
        return response.data.data;
    }

    static async putAbfragezeit(q: AbfragezeitenDTO): Promise<ApiResponse<AbfragezeitenDTO>> {
        const response = await apiClient.put('/ou/abfragezeiten', q);
        store.dispatch("crudEvent", new CrudEvent(Event.abfragezeitenUpdate, response.data.data));
        return response.data;
    }

    static async removeAbfragezeit(id: number): Promise<AbfragezeitenDTO> {
        const response = await apiClient.delete('/ou/abfragezeiten' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.abfragezeitenUpdate, response.data.data, id));
        return response.data;
    }



    static async getMerkmale(): Promise<OrgUnitMerkmalDTO[]> {
        const response = await apiClient.get('/ou/oumerkmale');
        return response.data.data;
    }


    static async getTypes(): Promise<OrgUnitTypDTO[]> {
        const response = await apiClient.get('/ou/outyp');
        return response.data.data;
    }

    static async getEvd(param = ''): Promise<OrgUnitEvdDTO> {
        const response = await apiClient.get('/ou/evd/' + param);
        return response.data.data;
    }
    static async getDisplayEvd(param = ''): Promise<OrgUnitEvdResponseDTO> {
        const response = await apiClient.get('/ou/evd/display' + param);
        return response.data.data;
    }

    static async putEvd(q: OrgUnitEvdDTO): Promise<OrgUnitEvdDTO> {
        const response = await apiClient.put('/ou/evd', q);
        return response.data;
    }



}
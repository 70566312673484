var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card dashboard-card shadow-lg mr-3 mb-2 dashboardCardSize"},[_vm._m(0),_c('p',{staticClass:"text-secondary"},[_vm._v("Aufgaben und Infos")]),_c('div',{staticClass:"card-body text-center"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('button',{staticClass:"btn dashboardButton border text-truncate position-relative d-flex align-items-center justify-content-between p-0",on:{"click":_vm.routeTasks}},[_vm._m(1),(_vm.loading)?[_c('b-spinner',{staticClass:"spinner-container"})]:[(_vm.openTasks)?_c('div',{staticClass:"badge-container"},[(_vm.openTasks)?_c('div',{staticClass:"badgeDashboard badge-danger flex-grow-1"},[_vm._v(_vm._s(_vm.openTasks))]):_vm._e()]):_c('div',{staticClass:"badge-container"},[_vm._m(2)])]],2),_c('button',{staticClass:"btn dashboardButton border text-truncate position-relative d-flex align-items-center justify-content-between p-0",on:{"click":_vm.routeTasks}},[_vm._m(3),(_vm.loading)?[_c('b-spinner',{staticClass:"spinner-container"})]:[(_vm.openTasksMassnahme)?_c('div',{staticClass:"badge-container"},[(_vm.openTasksMassnahme)?_c('div',{staticClass:"badgeDashboard badge-danger flex-grow-1"},[_vm._v(_vm._s(_vm.openTasksMassnahme))]):_vm._e()]):_c('div',{staticClass:"badge-container"},[_vm._m(4)])]],2),_c('button',{staticClass:"btn dashboardButton border text-truncate position-relative d-flex align-items-center justify-content-between p-0",on:{"click":_vm.routeTasks}},[_vm._m(5),(_vm.loading)?[_c('b-spinner',{staticClass:"spinner-container"})]:[(_vm.openInfos)?_c('div',{staticClass:"badge-container"},[(_vm.openInfos)?_c('div',{staticClass:"badgeDashboard badge-danger flex-grow-1"},[_vm._v(_vm._s(_vm.openInfos))]):_vm._e()]):_c('div',{staticClass:"badge-container"},[_vm._m(6)])]],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('span',{staticClass:"d-flex justify-content-center"},[_c('i',{staticClass:"fas fa-clipboard card-img-top text-secondary"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-items-center p-2"},[_c('i',{staticClass:"fas fa-clipboard text-secondary mr-1"}),_vm._v(" Aufgaben ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"badgeDashboard badge-success flex-grow-1"},[_c('i',{staticClass:"fas fa-check-circle fa-xl"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-items-center p-2"},[_c('i',{staticClass:"fas fa-clipboard text-secondary mr-1"}),_vm._v(" Maßnahmen ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"badgeDashboard badge-success flex-grow-1"},[_c('i',{staticClass:"fas fa-check-circle fa-xl"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex align-items-center p-2"},[_c('i',{staticClass:"fas fa-circle-info text-secondary mr-1"}),_vm._v(" Infos ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"badgeDashboard badge-success flex-grow-1"},[_c('i',{staticClass:"fas fa-check-circle fa-xl"})])
}]

export { render, staticRenderFns }
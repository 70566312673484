import {AbstractListItemDTO, EntityStandortDTO, GegenstandListItemDTO, KfzDTO, MaterialDTO, MedProduktDTO, OrgUnitDTO, OutsourcingDTO, StandortwechselDTO, UserResponseDTO} from "@/model/dto";
import {Vue} from "vue-property-decorator";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {UserId} from "@/model/Constants";
import {AbstractClass} from "@/model/AbstractClasses";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";
import {v4 as uuid} from 'uuid';


export class EntityStandort extends AbstractClass implements EntityStandortDTO {
    customLocation: boolean;
    kfz: KfzDTO | null;
    kfzStandort: boolean;
    material: MaterialDTO | null;
    materialStandort: boolean;
    medProduktStandort: boolean;
    name: string;
    orgUnit: OrgUnitDTO | null;
    outsourcing: OutsourcingDTO | null;
    passOnChilds: boolean;
    dto: any;
    type: string | null;
    icon: string | null;
    searchText: string | null;
    text: string | null;
    abstract: boolean;
    archive: boolean;
    typeText: string | null;
    iconPlain: string | null;
    user: UserResponseDTO | null;
}

export class Standortwechsel extends AbstractClass implements StandortwechselDTO {
    material: MaterialDTO[] = [];
    medProdukt: MedProduktDTO[] = [];
    neuerStandort: EntityStandortDTO | null;
}

export class GegenstandListItem extends AbstractClass implements GegenstandListItemDTO {
    name: string
    text: string
    searchText: string

    type: string
    dto: any

    constructor(dto: AbstractListItemDTO) {
        super()
        const type = dto.type

        this.text = dto.text
        this.searchText = dto.searchText
        this.dto = dto
        this.id = type + dto.id
        this.type = type
    }
}

export class ListItemHelper {
    static trim(fieldValue, maxLength = 50) {
        if (fieldValue.length > maxLength) {
            const trimmedText = fieldValue.substring(0, maxLength) + "...";

            return "<notexport>" + trimmedText + "</notexport>"
                + "<span style=\"display: none;\">" + fieldValue + "</span>";
        } else {
            return fieldValue;
        }
    }

    static getTag(dto) {
        const listItem = new Vue({
            // eslint-disable-next-line vue/no-unused-components
            components: {ListItemComponent},
            template: `
              <list-item-component :dto="dto" :box="true"/>`,
            data: () => ({dto: dto})
        }).$mount()

        return listItem.$el.outerHTML
    }

    static getInheritance(fieldId = null) {
        if (!fieldId) {
            fieldId = uuid()
        }
        const listItem = new Vue({
            // eslint-disable-next-line vue/no-unused-components
            components: {ChainIconTooltipTableCell},
            template: `
              <chain-icon-tooltip-table-cell :is-cell="true" :uuid="id"></chain-icon-tooltip-table-cell>`,
            data: () => ({
                id: fieldId
            })
        }).$mount()

        return listItem.$el.outerHTML
    }
}

export class AdditionalFieldHelper {

    static getTableColumns(cols) {
        return cols.map(col => (
            {
                class: '',
                data: null,
                title: "" + col.name,
                render: function (d, type, row) {
                    const data = d[col.identifier]
                    if (!data) return "-"
                    if (Array.isArray(data.medProdukt) && data.medProdukt.length > 0) {
                        const resultArr = [];
                        data.medProdukt.forEach(mp => resultArr.push(ListItemHelper.getTag(mp)));
                        return resultArr.join("<span class='d-none'>; </span>");
                    } else if (Array.isArray(data.kfz) && data.kfz.length > 0) {
                        const resultArr = [];
                        data.kfz.forEach(mp => resultArr.push(ListItemHelper.getTag(mp)));
                        return resultArr.join("<span class='d-none'>; </span>");
                    } else if (Array.isArray(data.material) && data.material.length > 0) {
                        const resultArr = [];
                        data.material.forEach(mp => resultArr.push(ListItemHelper.getTag(mp)));
                        return resultArr.join("<span class='d-none'>; </span>");
                    } else if (data.user) {
                        return data.user.id == UserId.FREITEXT ? data.userText : data.user.formatted;
                    } else if (data.dienst) {
                        return data.dienst.name;
                    } else if (Array.isArray(data.kennung) && data.kennung.length > 0) {
                        return data.kennung.map(k => k.name).join(", ");
                    } else if (data.file) {
                        return data.file != null ? '<button file="' + data.file.id + '" class="btn btn-outline-info table-button-download" type="button"><i class="fas fa-download" /></button>' : ''
                    }

                    return data.fieldValue ? ListItemHelper.trim(data.fieldValue, 100)
                        : (typeof data !== "object") ? data : "-";
                }
            }
        ))
    }
}

export class HistoryEvent {
    public path: string
    public method: string
    public id: string

    constructor(path, id = null, method = null) {
        this.path = path
        this.id = id

        if (method) {
            this.method = method
        } else {
            this.method = (this.id) ? 'HISTORY' : 'POST'
        }
    }
}

import {Component, Prop, Vue} from "vue-property-decorator";
import {Module} from "@/model/Constants";
import {CheckinDTO, GenericError, TaskDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import EntityTaskTemplateSelectorView from "@/views/task/entity/EntityTaskTemplateSelectorView.vue";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import Multiselect from '@/libs/multiselect'
import {TaskApi} from "@/services/TaskApi";
import {ApiResponse} from "@/model/AbstractClasses";
import {EntityTtoCheckinTask} from "@/model/Task";

@Component({
  components: {ListItemComponent, MedProduktChooser, EntityTaskTemplateSelectorView, Multiselect}
})
export default class EntityTtoCheckinTaskSelector extends mixins<GenericMixin<TaskDTO, GenericError>>(GenericMixin, Vue) {
  @Prop({default: false}) value!: boolean;
  @Prop() checkin: CheckinDTO;

  error = new GenericError();
  entity = null;
  moduleId = Module.KFZ;

  medProdukt = null
  medproduktOpts = []

  material = null
  materialOpts = []

  async created() {
    this.entity = this.checkin.kfz ? this.checkin.kfz : null;
  }


  get showModal() {
    return this.value
  }

  set showModal(value) {
    this.$emit('input', value)
  }

  selected(entityTto) {
    const payload = new EntityTtoCheckinTask(this.checkin.id, entityTto)
    const p = TaskApi.postCheckinTask(payload);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.showModal = false
        this.$router.push('/checkintasklist/' + this.checkin.id)
      }
    })
  }

  async tabChanged(tabId) {
    this.entity = null
    this.medProdukt = null
    this.material = null

    switch (tabId) {
      case 0:
        this.entity = this.checkin.kfz ? this.checkin.kfz : null;
        this.moduleId = Module.KFZ
        break
      case 1:
        this.medproduktOpts = await this.$store.dispatch("checkin/fetchMedProdukte")
        this.moduleId = Module.MEDPRODUKT
        break
      case 2:
        this.moduleId = Module.MATERIAL
        this.materialOpts = await this.$store.dispatch("checkin/fetchMaterial")
        break
    }
  }
}

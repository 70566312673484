
import {Component, Vue} from "vue-property-decorator";

@Component
export default class NavMixin extends Vue {
  fillNavbar(routes, navBar) {
    routes.forEach((item) => {
      let route = this.$router.getRoutes().find(r => r.name == item)
      if (!route || !route.meta) {
        return;
      }

      if (route.meta.permission && !this.$store.getters.hasPermission(route.meta.permission[0], route.meta.permission[1])) {
        return;
      }

      navBar.push({name: route.name, path: route.path, title: route.meta.title, activeRoutes: (route.meta.activeRoutes ? route.meta.activeRoutes : [])})
    })
  }

  isActive(item) {
    return this.$route.name == item.name || (item.activeRoutes.includes(this.$route.name))
  }

}


import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FormVersionDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {FormRow, FormVersion} from "@/model/FormVersion";
import draggable from "vuedraggable";
import AdditionalFieldForm from "@/components/AdditionalFieldForm.vue";
import {AdditionalField} from "@/model/AbstractClasses";
import HtmlEditor from "@/components/HtmlEditor.vue";
import {FormApi} from "@/services/FormApi";
import {FileApi} from "@/services/FileApi";

@Component({
  components: {
    HtmlEditor,
    Multiselect, draggable,
    AdditionalFieldForm
  }
})
export default class FormVersionDataView extends mixins<GenericMixin<FormVersionDTO, FormVersionDTO>>(GenericMixin) {
  @Prop() value: FormVersion;
  @Prop() error: FormVersion;
  data: FormVersionDTO = this.$props.value;
  unsavedImages = []
  MAX_ELEMENTS = 6

  loadingComplete = false

  defaultColor = '#000000'

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.loadingComplete = true
    this.unsavedImages = []
    if (this.value) {
      this.value.rows.forEach(row => {
        if (row.lineBottom) {
          this.defaultColor = row.lineBottom
        } else if (row.lineTop) {
          this.defaultColor = row.lineTop
        }
      })
    }
  }

  onChange(event) {
    this.reorder()
  }

  colorChanged(val) {
    this.defaultColor = val
  }

  removeZeile(rowIndex) {
    this.data.rows.splice(rowIndex, 1)
  }

  checkMove(event) {
    if (event.relatedContext.list.length >= this.MAX_ELEMENTS) {
      this.$alert("Es dürfen maximal " + this.MAX_ELEMENTS + " Felder in einer Zeile sein!");
      return false
    }
    return true
  }

  addField(idx) {
    let row = this.data.rows[idx]
    if (row.fields.length >= this.MAX_ELEMENTS) {
      this.$alert("Es dürfen maximal " + this.MAX_ELEMENTS + " Felder in einer Zeile sein!");
      return false
    }

    let field = new AdditionalField();
    row.fields.push(field);
    this.reorder()
  }

  removeField(row, idx) {
    if (!row.fields[idx].id) {
      row.fields.splice(idx, 1)
      this.reorder()
    } else {
      this.$confirm('Wollen Sie das Feld wirklich unwiderruflich sofort löschen?', '', 'question', {
        confirmButtonText: 'Ja',
        cancelButtonText: 'Nein'
      }).then(() => {
        FormApi.removeField(row.fields[idx].id).then((d) => {
          row.fields.splice(idx, 1)
          this.reorder()
        }).catch(() => {
          this.$alert("Das Feld kann nicht gelöscht werden!", "Fehler", "error");
        })
      });
    }
  }


  reorder() {
    this.data.rows.forEach((row, indexRow) => (row.fields.forEach((item, index) => (item.sortOrder = index))))
  }

  addRow() {
    let row = this.createFormRow()
    this.data.rows.push(row);
    this.reorderRows()
  }

  addRowAbove(index: number) {
    let row = this.createFormRow()
    this.data.rows.splice(index, 0, row);
    this.reorderRows();
  }

  addRowBelow(index: number) {
    let row = this.createFormRow()
    this.data.rows.splice(index + 1, 0, row);
    this.reorderRows();
  }

  createFormRow() {
    let row = new FormRow();
    row.title = null
    row.lineBottom = null
    row.lineTop = null
    row.isDescription = false
    row.description = null
    row.fields = []
    return row

  }

  reorderRows() {
    this.data.rows.forEach((item, index) => (item.sortOrder = index))
  }

  removeZeilenueberschrift(row) {
    if (row.isDescription) {
      row.title = null
    }
  }

  handleImageAdded(id) {
    this.data.images.push(id)
    this.unsavedImages.push(id)
  }

  handleImageRemoved(id) {
    if (!this.data.id) {
      FileApi.removeImage("formversion-0-" + id)
    } else if (this.unsavedImages.includes(id)) {
      FileApi.removeImage("formversion-" + this.data.id + "-" + id)
    }
    this.data.images = this.data.images.filter(i => i !== id)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

}

import { render, staticRenderFns } from "./DivaView.vue?vue&type=template&id=6595d690&"
import script from "./DivaView.vue?vue&type=script&lang=ts&"
export * from "./DivaView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
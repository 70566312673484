import {apiClient} from './AbstractHTTPService'
import {CheckinDTO, CheckinPersonalDTO, CheckinRequestDTO} from "@/model/dto";
import store from "@/store";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";


export abstract class CheckinApi {
    static async getCheckin(param): Promise<ApiResponse<CheckinDTO>> {
        const response = await apiClient.get('/checkin/checkin/' + param);
        return new ApiResponse(response);
    }

    static async putCheckin(q: CheckinDTO): Promise<ApiResponse<CheckinDTO>> {
        const response = await apiClient.put('/checkin/checkin', q);
        store.dispatch("crudEvent", new CrudEvent(Event.checkinUpdate, response.data.data));
        return new ApiResponse(response);
    }

    static async getCheckins(param: CheckinRequestDTO): Promise<CheckinDTO[]> {
        const response = await apiClient.post('/checkin/checkin/', param);
        return response.data.data;
    }


    static async changePersonel(q: CheckinPersonalDTO): Promise<ApiResponse<CheckinDTO>> {
        const response = await apiClient.put('/checkin/changepersonal', q);
        store.dispatch("crudEvent", new CrudEvent(Event.checkinUpdate, response.data.data));
        return new ApiResponse(response);
    }

    static async removeCheckin(id: number): Promise<CheckinDTO> {
        const response = await apiClient.delete('/checkin/checkin/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.checkinUpdate, response.data.data, id));
        return response.data;
    }


}

import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {LevelOuDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {LevelOu, Tag, TagOu} from "@/model/Emeld";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import EmeldTagDataView from "@/views/emeld/data/EmeldTagDataView.vue";
import emeld from "@/store/modules/emeld";
import EmeldTagOuDataView from "@/views/emeld/data/EmeldTagOuDataView.vue";
import EmeldNotificationDataComponent from "@/views/emeld/components/EmeldNotificationDataComponent.vue";
import LabelComponent from "@/components/LabelComponent.vue";

@Component({
  computed: {
    emeld() {
      return emeld
    }
  },
  components: {
    LabelComponent,
    EmeldNotificationDataComponent,
    EmeldTagOuDataView,
    EmeldTagDataView,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class EmeldLevelDataView extends mixins<GenericMixin<LevelOuDTO, LevelOuDTO>>(GenericMixin) {
  @Prop() value: LevelOu;
  @Prop() error: LevelOu;
  data: LevelOu = this.$props.value;

  loadingComplete = false

  statusOptions = []
  personenkreisOptions = []

  async created() {
    window.scrollTo(0, 0);
    this.init()
  }


  async init() {
    this.statusOptions = this.data.level.status
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")

    this.loadingComplete = true
  }

  removeTag(idx) {
    let tagOuToRemove = this.data.tagsOu[idx]
    console.log(tagOuToRemove)
    this.data.level.tags = this.data.level.tags.filter(tag => tag.id != tagOuToRemove.tag.id)
    this.data.tagsOu.splice(idx, 1)
  }

  addTag() {
    let tag = new Tag();
    tag.info = []
    tag.bewertungType = 0

    let tagOu = new TagOu();
    tagOu.tag = tag;
    tagOu.own = true
    tagOu.info = []

    this.data.tagsOu.push(tagOu);

    this.$nextTick(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      })
    })
  }



  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }

}

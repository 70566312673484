
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {MedProduktEwmpgFileCategoryDTO} from "@/model/dto";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import {FileApi} from "@/services/FileApi";
import MedProduktFileView from "@/views/medprodukt/MedProduktFileView.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import {MedProduktHelper} from "@/model/MedProdukt";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";


@Component(
    {
      components: {ListItemComponent, PseudoTag, MedProduktFileView, PersonenkreisTag},
    }
)
export default class MedProduktFileList extends Vue {
  fileCategories: MedProduktEwmpgFileCategoryDTO[] = []
  key = 0
  maxTags: number;
  clb = null;
  selectedElem = null
  showModal = false

  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']

    this.selectedElem = this.$store.state.medprodukt.filter.fileCategory
    this.clb = (fileCategory) => {
      this.selectedElem = fileCategory
      this.key++
    }

    this.fileCategories = await FileApi.getMedProduktEwmpgFileCategoryOverviewChain();

    bus.$on(Event.medProduktFileCategoryFilter, this.clb)
  }

  destroyed() {
    bus.$off(Event.medProduktFileCategoryFilter, this.clb)
  }

  get personenkreise() {
    let arr = []
    this.fileCategories.filter((ufk) => ufk.fileCategory.id == this.selectedElem.id).some((ufk: MedProduktEwmpgFileCategoryDTO) => {
      arr.push(...MedProduktHelper.getTagFromFileCategory(ufk));
    })
    return arr
  }

  openModal() {
    this.showModal = true
  }

}


import {Component, Vue} from "vue-property-decorator";
import UserProfilePersonView from "@/views/profile/UserProfilePersonView.vue";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {UserEdit} from "@/model/User";
import {UsersApi} from "@/services/UserApi";
import UserChangePinView from "@/views/profile/UserChangePinView.vue";
import jquery from "jquery";
import QrcodeVue from 'qrcode.vue'
import UserProfileDressSizeView from "@/views/profile/UserProfileDressSizeView.vue";
import {ApiResponse, ExistingFiles, ObjectEvent, RewisFile} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import UserPermissionView from "@/views/user/UserPermissionView.vue";
import UserQualificationView from "@/views/user/UserQualificationView.vue";
import UserSkillView from "@/views/user/UserSkillView.vue";
import FileUpload from "@/components/FileUpload";
import {Permission, PermissionModul, UserQualificationDTO} from "@/model/dto";
import UserDataView from "@/views/user/UserDataView.vue";
import UserDateUserView from "@/views/user/UserDateUserView.vue";
import UserFileUserView from "@/views/user/UserFileUserView.vue";
import UserEinweisungUserView from "@/views/user/UserEinweisungUserView.vue";
import FobiUserOverviewComponent from "@/views/fobi/components/FobiUserOverviewComponent.vue";
import FobiZeitraumKummulativView from "@/views/fobi/FobiZeitraumKummulativView.vue";
import UserMaterialAusgabeUserView from "@/views/user/UserMaterialAusgabeUserView.vue";
import {FileApi} from "@/services/FileApi";
import CampusUserOverviewTable from "@/views/fobi/CampusUserOverviewTable.vue";
import FobiUserOverviewTable from "@/views/fobi/FobiUserOverviewTable.vue";


const DataProps = Vue.extend({
  props: {
    id: String,
    archive: Boolean,
    from: String
  }
})
@Component({
  computed: {
    Module() {
      return Module
    },
    FileApi() {
      return FileApi
    }
  },
  components: {
    FobiUserOverviewTable,
    CampusUserOverviewTable,
    UserMaterialAusgabeUserView,
    FobiZeitraumKummulativView,
    FobiUserOverviewComponent,
    UserEinweisungUserView,
    UserFileUserView,
    UserDateUserView,
    UserDataView,
    UserSkillView,
    FileUpload,
    UserQualificationView,
    UserPermissionView, UserProfilePersonView, UserProfileDressSizeView, UserChangePinView, QrcodeVue
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class UserEditView extends mixins<GenericMixin<UserEdit, UserEdit>>(GenericMixin, DataProps) {
  data = new UserEdit();
  error = new UserEdit();
  showTabs = false
  tabIndex = 0
  errorTabs = []
  showTempConfirm = false

  basePath = process.env.VUE_APP_REWIS
  showProfilePhotoUploadModal = false
  existingProfilePhoto = null
  qualification: UserQualificationDTO | null = null

  created() {
    this.init();

    bus.$on(Event.userQualificationChanged, (payload: ObjectEvent<UserQualificationDTO | null>) => {
      console.log('userquali')
      this.qualification = payload.data
    })
  }

  destroyed() {
    bus.$off(Event.userQualificationChanged);
  }

  init = function () {
    this.$root.$emit('loadingStart')
    UsersApi.getMaxQualificationOfUser(this.$props.id).then((q) => {
      this.qualification = q
    })
    const p = UsersApi.getUser(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true

      if (this.data.profilePhoto) this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
    })
    this.uploadKey += 1
  }

  downloadQrCode = function () {
    let wrapper = document.getElementById("myQrCode");
    let canvas = wrapper.firstChild

    let link = document.createElement('a');
    link.download = 'qrcode.png';
    link.href = canvas.toDataURL("image/png");
    link.click();
  }

  editProfilePhoto() {
    this.showProfilePhotoUploadModal = true
  }

  profilePhotoFileUploadSuccess(d) {
    let file = new RewisFile(d);

    UsersApi.updateUserPhoto(this.data.id, file)
        .then((photo) => {
          this.showProfilePhotoUploadModal = false
          this.data.profilePhoto = photo
          this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
        })
        .catch((e) => {
          this.$alert("Beim Upload des Profilbiles trat ein Fehler auf!");
        });
  }

  profilePhotoFileDelete(d) {
    UsersApi.removeUserPhoto(this.data.id)
        .then((photo) => {
          this.data.profilePhoto = null
          this.existingProfilePhoto = null
        })
        .catch((e) => {
          this.$alert("Beim Löschen des Profilbiles trat ein Fehler auf!");
        });
  }

  archiv() {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      UsersApi.archivUser(this.data.id)
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.userArchived, new ObjectEvent(this.$props.id, this.data));
            this.goBack()
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " des Benutzers trat ein Fehler auf!");
          })
    });
  }

  inBearbeitung() {
    this.$confirm('Noch in Bearbeitung!', '', 'info', {
      confirmButtonText: 'Ok verstanden!',
      cancelButtonText: 'Ok verstanden!'
    }).then(() => {
      this.showTempConfirm = !this.showTempConfirm
    })
  }

  lionForm() {
    FileApi.download('/lion/form/' + this.data.id, true, false, true)
  }

  qr() {
    FileApi.download('/user/userlist/qr/'+this.data.id, true, false, true)
  }


  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = UsersApi.updateUser(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      jquery("#usertabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );

      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send userChanged and userDressSizeChanged event')
        bus.$emit(Event.userChanged, new ObjectEvent(this.$props.id, data.data));
        bus.$emit(Event.userDressSizeChanged, new ObjectEvent(this.$props.id, data.data.dressSize));
        if (goBack) {
          this.goBack()
        }
      }
    })
  }

  goBack() {
    let from = this.$props.from ? this.$props.from : this.$store.state.route.from.fullPath;
    if (!from.includes("user")) {
      from = "/user/"
    }

    this.$router.push({path: from})
  }

  get archivTxt() {
    if (!this.$store.getters.hasPermission(PermissionModul.User, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)
  }

  get savePermission() {
    return this.editPermission || this.$store.getters.hasPermission(PermissionModul.User_Clothes, Permission.Update)
  }

  get einweisungPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Overview)
  }

  get materialPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material, Permission.Overview)
  }

  get fobiPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Overview)
  }

  get filePermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Files, Permission.Overview)
  }

  get datePermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Dates, Permission.Overview)
  }

  get clothesPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Clothes, Permission.Overview)
  }
}

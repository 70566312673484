
import {DateHelper, DateHelper as dateHelper} from "../../model/Date";
import {Task, TaskProcess, TaskRequest} from "@/model/Task";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {Component, Prop, Vue} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {TaskApi} from "@/services/TaskApi";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import {func} from '@/scripts/scripts'
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import TaskNotizModal from "@/views/task/components/TaskNotizModal.vue";
import TaskModal from "@/views/task/components/TaskModal.vue";
import TaskChatModal from "@/views/task/components/TaskChatModal.vue";
import {Route} from "vue-router";
import {GenericError, TaskDTO} from "@/model/dto";
import {FormApi} from "@/services/FormApi";
import DesiCreateView from "@/views/hygiene/DesiCreateView.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import DateForm from "@/components/DateForm.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {MedProduktHelper} from "@/model/MedProdukt";
import {ListItemHelper} from "@/model/Shared";

@Component({
  components: {
    DateForm,
    FormInput,
    DesiCreateView,
    TaskChatModal, TaskModal, TaskNotizModal, DatatableButtonHeader, ListItemComponent
  },
  computed: {
    Task() {
      return Task
    }
  },
  watch: {
    '$route'(val: Route) {
      if (val.name.includes("mytasktable")) {
        this.table.fixedHeader.adjust();
        this.table.fixedHeader.enable()
        this.$root.$emit('loadingDone');
      } else {
        this.table.fixedHeader.disable()
      }
      this.handleDatableRoute(true);
    }
  }
})

export default class TaskTableView extends mixins<SimpleViewMixin, GenericMixin<null, null>>(SimpleViewMixin, GenericMixin) {

  @Prop() tasks
  @Prop() isCheckin
  tableId = 'dTable_' + uuid();
  dateHelper = DateHelper
  showModal = false
  showChatModal = false
  showNotizModal = false
  selectedItem = null
  formRows = []
  cleanText = ""
  showProcessModal = false
  showHygieneModal = false
  taskStatusOptions = []
  table = null
  request = null
  helper = ListItemHelper

  clb = () => {
    this.$root.$emit('loadingStart');
    this.reloadTable()
  }

  async created() {
    this.taskStatusOptions = await this.$store.dispatch("task/fetchStatus")
    bus.$on(Event.taskFilterChanged, this.clb)
  }

  getUrl() {
    this.request = new TaskRequest();
    this.request.archive = false
    const filter = {...this.$store.getters['task/getTaskRequestFilter']};
    if (filter.statusId) {
      this.request.statusId = filter.statusId
    }
    if (filter.typId) {
      this.request.typId = filter.typId
    }

    return '/task/mytask/'
  }

  mounted() {
    this.init()
  }

  async init() {

    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
    }
    this.registerEvents([Event.taskChanged]);

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: self.getUrl(),
        type: 'POST',
        contentType: 'application/json',
        data: function () {
          return JSON.stringify(self.request);
        },
        error: function (xhr, error, thrown) {
          console.error('Error loading data:', error, thrown);
        },
        dataSrc: function (response) {
          if (response && response.data) {
            return response.data;
          } else {
            console.warn('Unexpected response format');
            return [];
          }
        }
      }),
      fixedHeader: {
        header: true,
        headerOffset: 190,
      },
      stateSave: false,
      responsive: false,
      rowId: 'id',
      columns: [
        {data: 'id'},
        {data: 'title'},
        {
          data: null,
          render: function (data) {
            if (data.kfz) {
              return self.helper.getTag(data.kfz)
            } else if (data.medProdukt) {
              return self.helper.getTag(data.medProdukt)
            } else if (data.material) {
              return self.helper.getTag(data.material)
            } else {
              return '';
            }
          }
        },
        {data: 'status.name'},
        {
          data: null,
          render: function (data) {
            return self.dateHelper.parseDate(data.faelligkeit);
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {
            return data.zustaendig.map(val => val.text).join(', ');
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {
            return self.parseTyp(data.typ);
          },
          visible: !self.isCheckin
        },
        {
          data: null,
          render: function (data) {

            return `
              <button class="btn tableEditOrRemoveButton btn-more" data-id="${data.id}"' title="Mehr anzeigen">
                <i class="fas fa-angle-double-right"></i>
              </button>
              <button class="btn tableEditOrRemoveButton btn-chats" data-id="${data.id}"' title="Chats">
                <i class="fas fa-comments"></i>
              </button>
              <button class="btn tableEditOrRemoveButton btn-note" data-id="${data.id}"' title="Notizen">
                <i class="fas fa-sticky-note"></i>
              </button>
              <button class="btn tableEditOrRemoveButton btn-process text-success" data-id="${data.id}"' title="Abarbeiten">
                <i class="fas fa-clipboard-check"></i>
              </button>
            `;
          }
        }
      ],
      select: true,
      order: [0, 'asc'],
      buttons: func.addDefaultButtons(),
      initComplete: function () {
        self.$root.$emit('loadingDone')
      }
    });
    self.table = table
    func.datatableInitComplete(table)

    jquery('#' + this.tableId + ' tbody').on('click', '.btn-more', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.openModal(item);
    });

    jquery('#' + this.tableId).on('click', '.btn-chats', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.chats(item);
    });

    jquery('#' + this.tableId).on('click', '.btn-note', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.notizen(item);
    });

    jquery('#' + this.tableId).on('click', '.btn-process', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      const item = self.table.row("#" + rowId).data()
      self.process(item);
    });
  }

  /*initDataTable() {

  }*/


  openModal(item) {
    this.selectedItem = item
    this.showModal = true
  }

  chats(item) {
    this.selectedItem = item
    this.showChatModal = true
  }

  notizen(item) {
    this.selectedItem = item
    this.showNotizModal = true
  }


  async process(item: TaskDTO) {
    this.selectedItem = item
    this.data = new TaskProcess()
    this.error = new GenericError()

    if (item.form) {
      this.formRows = await FormApi.getFormRows({forms: [item.form]});
    } else {
      this.formRows = []
    }


    this.data.id = item.id
    this.data.addFields = item.addFields
    this.data.statusId = Task.STATUS_DONE
    this.data.zurueckBis = item.zurueckBis

    this.cleanText = this.$sanitize(item.text)
    this.showProcessModal = true
  }

  submit() {
    const p = TaskApi.putMyTask(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        this.selectedItem = this.data.task;
        let obj = this.objects.find(item => item.id == this.data.id)
        if (obj) {
          obj.status = this.data.task.status
          obj.addFields = this.data.task.addFields
        }
        bus.$emit(Event.taskChanged, new ObjectEvent(data.data.id, data.data))
        this.showProcessModal = false
      }
    })
  }

  get filteredStatusOptions() {
    return this.taskStatusOptions.filter(option => option.value != 4 && option.value != 98) //storno, nichtabgerufen filtern
  }

  hygiene(item) {
    this.selectedItem = item
    this.showHygieneModal = true
  }

  parseTyp(typ) {
    let typs = this.$store.getters['task/getTaskTyp']
    let typsFiltered = typs.find(v => v.value == typ)
    if (typsFiltered) {
      return typsFiltered.text
    } else {
      return 'Unbekannt'
    }
  }


  cardView() {
    this.$router.push('/task/mytask')
  }

  destroyed() {
    this.table.fixedHeader.disable()

    this.unregisterEvents()
  }
}

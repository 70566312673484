
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, OrgUnitDTO, OrgUnitMerkmalDTO, OrgUnitTypDTO, PermissionModul} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import Multiselect from "@/libs/multiselect";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import {OrgUnit} from "@/model/OrgUnit";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {SimpletableButtonHeader, Multiselect}
})
export default class OrgUnitListView extends mixins<SimpleViewMixin<OrgUnitDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: OrgUnitDTO = new OrgUnit()
  error: GenericError = new GenericError()

  loading = true
  ouMerkmale: OrgUnitMerkmalDTO[] = []
  ouTypes: OrgUnitTypDTO[] = []
  modulPermissions = []

  async created() {
    await this.reload()
  }

  async reload() {
    this.loading = true

    await this.init(OrgUnit,
        OrgUnitApi.getOrgUnitsExtended,
        (a) => {
          a.module = a.module.map(m => m.identifier)
          return OrgUnitApi.putOrgUnit(a)
        },
        (b) => (b) => {
          return false
        }
    )

    this.modulPermissions = this.$store.getters.getConstants.permissions;

    this.ouMerkmale = await OrgUnitApi.getMerkmale()
    this.ouTypes = await OrgUnitApi.getTypes()
    this.$root.$emit('loadingDone')
    this.loading = false
  }

  get moduleObject() {
    this.data.module = this.data.module.map(m => {
      const obj = (m !== null && typeof m === 'object') ? m : this.modulPermissions.find(p => p.identifier == m)
      return obj ? obj : null
    }).filter(o => o !== null)
    return this.data.module
  }

  set moduleObject(value) {
    this.data.module = value
  }

  typChanged() {
    if (this.data.typ.name.toLowerCase().includes("li.on")) {
      this.moduleObject = [PermissionModul.MYPROFILE, PermissionModul.MYPASSWORD, PermissionModul.User, PermissionModul.User_Dates]
    } else {
      this.moduleObject = []
    }
  }

}

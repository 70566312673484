import {apiClient} from './AbstractHTTPService'

const PATH = '/file/'

export default {
    getCategories() {
        return apiClient.get('/basedata/filecategory/')
    },

    download(data, bForceDownload = false) {
        alert('Die Download-Funktion wird erst später bereitgestellt. Download id: ' + data.id)

        /*
        return new Promise(function (resolve, reject) {
            apiClient({
                method: 'GET',
                url: PATH + data.id + '/',
                responseType: 'blob'
            })
                .then(response => {
                    if (!bForceDownload && response.headers['content-type'].startsWith('image')) {
                        var arrayPromise = new Promise(function (resolve) {
                            var reader = new FileReader();

                            reader.onloadend = function () {
                                resolve(reader.result);
                            };

                            reader.readAsArrayBuffer(response.data);
                        });

                        arrayPromise.then(function (array) {
                            var binary = '';
                            var bytes = new Uint8Array(array);
                            var len = bytes.byteLength;
                            for (var i = 0; i < len; i++) {
                                binary += String.fromCharCode(bytes[i]);
                            }

                            const base64String = btoa(binary);
                            resolve('data:' + response.headers['content-type'] + ';base64,' + base64String)
                        });
                    } else {
                        var FileSaver = require('file-saver');
                        var blob = new Blob([response.data], { type: response.headers['content-type'] })
                        FileSaver.saveAs(blob, data.sFilename);
                        resolve(false)
                    }
                })
                .catch((e) => {
                    console.log(e)
                    reject(e)
                })
        });*/
    }
}

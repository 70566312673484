
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {KfzFileCategoryDTO} from "@/model/dto";
import PersonenkreisTag from "@/components/PersonenkreisTag.vue";
import KfzFileView from "@/views/kfz/KfzFileView.vue";
import PseudoTag from "@/components/PseudoTag.vue";
import {bus} from "@/main";
import {Event} from "@/model/Constants";


@Component(
    {
      components: {PseudoTag, KfzFileView, PersonenkreisTag},
    }
)
export default class KfzFileList extends Vue {
  fileCategories: KfzFileCategoryDTO[] = []
  key = 0
  showAllTags = false
  maxTags: number;
  clb = null;
  selectedElem = null
  showModal = false


  async created() {
    this.maxTags = this.$store.getters['tags/getMaxTags']

    this.selectedElem = this.$store.state.kfz.filter.fileCategory
    this.clb = (fileCategory) => {
      this.selectedElem = fileCategory
      this.key++
    }

    this.fileCategories = await this.$store.dispatch("kfz/fetchDateiKategorienOu")
    bus.$on(Event.kfzFileCategoryFilter, this.clb)
  }

  destroyed() {
    bus.$off(Event.kfzFileCategoryFilter, this.clb)
  }

  get personenkreise() {
    let arr = []
    this.fileCategories.filter((ufk) => ufk.fileCategory.id == this.selectedElem.id).some((ufk: KfzFileCategoryDTO) => {
      if (ufk.forAll) {
        arr.push({text: "Alle Kfz", ouText: ufk.orgUnit.name, group: null})
      }

      ufk.kfz.forEach((kfz) => {
        arr.push({text: kfz.licensePlate, ouText: ufk.orgUnit.name, group: 'Fahrzeug'})
      })

      ufk.kfzCategory.forEach((kfz) => {
        arr.push({text: kfz.name, ouText: ufk.orgUnit.name, group: 'Kategorie'})
      })
    })


    return arr
  }

  openModal() {
    this.showModal = true
  }
}

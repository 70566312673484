import MaterialView from "@/views/material/MaterialView.vue";
import MaterialTables from "@/views/material/MaterialTables.vue";
import MaterialCreateView from "@/views/material/MaterialCreateView.vue";
import MaterialEditView from "@/views/material/MaterialEditView.vue";
import MaterialKlasseView from "@/views/material/MaterialKlasseView.vue";
import MaterialKategorieCreateView from "@/views/material/MaterialKategorieCreateView.vue";
import MaterialKategorieEditView from "@/views/material/MaterialKategorieEditView.vue";
import EwoHerstellerView from "@/views/ewmpg/EwoHerstellerView.vue";
import StandorteView from "@/views/shared/StandorteView.vue";
import MaterialDateSettingsView from "@/views/material/MaterialDateSettingsView.vue";
import MaterialDateList from "@/views/material/MaterialDateList.vue";
import DateTypCatalogView from "@/views/shared/DateTypCatalogView.vue";
import FileCategoryCatalogView from "@/views/shared/FileCategoryCatalogView.vue";
import MaterialFileList from "@/views/material/MaterialFileList.vue";
import MaterialFileCategoriesView from "@/views/material/MaterialFileCategoriesView.vue";
import {EntityTaskTemplateModus} from "@/model/Task";
import {Module} from "@/model/Constants";
import EntityTaskTemplateCreateView from "@/views/task/entity/EntityTaskTemplateCreateView.vue";
import EntityTaskTempalteEditView from "@/views/task/entity/EntityTaskTempalteEditView.vue";
import {Permission, PermissionModul} from "@/model/dto";


const moduleMeta = {
    entityTaskModus: EntityTaskTemplateModus.MATERIAL_TASK,
    module: Module.MATERIAL,
    entityKey: 'material'
}
export const materialRoutes = [

    {
        path: 'material',
        name: 'material',
        component: MaterialView,
        children: [
            {
                path: 'klassen',
                name: 'materialklassenlist',
                component: MaterialKlasseView,
                meta: {
                    loading: true,
                    title: 'Klassen',
                    permission: [PermissionModul.Material_Settings, Permission.MaterialKlasse]
                }
            },
            {
                path: 'standorte',
                name: 'materialstandorte',
                component: StandorteView,
                meta: {
                    loading: true,
                    title: 'Standorte',
                    permission: [PermissionModul.Material_Settings, Permission.Standorte]
                }
            },
            {
                path: 'kategorie',
                name: 'materialkategorielist',
                component: MaterialTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Materialarten',
                    permission: [PermissionModul.Material_Settings, Permission.MaterialArt]
                }
            },
            {
                path: 'kategorie/create',
                name: 'materialkategoriecreate',
                components: {
                    default: MaterialTables,
                    CRUD: MaterialKategorieCreateView,
                    permission: [PermissionModul.Material_Settings, Permission.MaterialArt]
                }
            },
            {
                path: 'kategorie/edit/:id',
                name: 'materialkategorieedit',
                components: {
                    default: MaterialTables,
                    CRUD: MaterialKategorieEditView
                },
                props: {
                    CRUD: true,
                    permission: [PermissionModul.Material_Settings, Permission.MaterialArt]
                },
            },
            {
                path: 'overview',
                name: 'materiallist',
                component: MaterialTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Übersicht',
                    permission: [PermissionModul.Material, Permission.Overview],
                    activeRoutes: ['materiallist', 'materialcreate', 'materialedit']
                }
            },
            {
                path: 'create/:parentId?',
                name: 'materialcreate',
                components: {
                    default: MaterialTables,
                    CRUD: MaterialCreateView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    permission: [PermissionModul.Material, Permission.Create]
                }
            },
            {
                path: 'edit/:id/:idx?',
                name: 'materialedit',
                components: {
                    default: MaterialTables,
                    CRUD: MaterialEditView
                },
                props: {
                    CRUD: true
                },
                meta: {
                    loading: true,
                    ...moduleMeta,
                    permission: [PermissionModul.Material, Permission.Overview]
                }
            },
            {
                path: 'hersteller',
                name: 'materialhersteller',
                component: EwoHerstellerView,
                meta: {
                    loading: true,
                    title: 'Hersteller',
                    permission: [PermissionModul.Material_Settings, Permission.Hersteller]
                }
            },
            {
                path: 'datetypcatalog',
                name: 'materialdatetypcatalog',
                component: DateTypCatalogView,
                meta: {
                    loading: true,
                    title: 'Termin-Katalog',
                    permission: [PermissionModul.Material_Settings, Permission.Termine_Katalog]

                }
            },
            {
                path: 'datesettings',
                name: 'materialdatesettings',
                component: MaterialDateSettingsView,
                meta: {
                    loading: true,
                    title: 'Termine',
                    permission: [PermissionModul.Material_Settings, Permission.Termine]
                }
            },
            {
                path: 'datelist',
                name: 'materialdatelist',
                component: MaterialDateList,
                meta: {
                    loading: false,
                    title: 'Termine',
                    permission: [PermissionModul.Material_Dates, Permission.Overview]
                }
            },
            {
                path: 'filecategorycatalog',
                name: 'materialfilecategorycatalog',
                component: FileCategoryCatalogView,
                meta: {
                    loading: true,
                    title: 'Dateikategorie-Katalog',
                    permission: [PermissionModul.Material_Settings, Permission.Dateikategorien_Katalog]
                }
            },

            {
                path: 'filecategory',
                name: 'materialfilecategory',
                component: MaterialFileCategoriesView,
                meta: {
                    loading: true,
                    title: 'verpflichtende Dateikategorien',
                    permission: [PermissionModul.Material_Settings, Permission.Dateikategorien]
                }
            },
            {
                path: 'filelist',
                name: 'materialfilelist',
                component: MaterialFileList,
                meta: {
                    loading: false,
                    title: 'Dateien',
                    permission: [PermissionModul.Material_Files, Permission.Overview]
                }
            },
            {
                path: 'tasktemplatelist',
                name: 'materialtasktemplatelist',
                component: MaterialTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Aufgabenvorlagen',
                    permission: [PermissionModul.Material_Task_Plan, Permission.Overview],
                    activeRoutes: ['materialtasktemplatelist', 'materialtasktemplatecreate', 'materialtasktemplateedit']
                }
            },
            {
                path: 'tasklist',
                name: 'materialtasklist',
                component: MaterialTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Aufgaben',
                    permission: [PermissionModul.Material_Task, Permission.Overview]
                }
            },
            {
                path: 'form',
                name: 'materialformlist',
                component: MaterialTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Formulare',
                    permission: [PermissionModul.Material_Settings, Permission.Formulare]
                }
            },
            {
                path: 'tasktemplate/create',
                name: 'materialtasktemplatecreate',
                components: {
                    component: MaterialTables,
                    CRUD: EntityTaskTemplateCreateView
                },
                meta: {
                    ...moduleMeta,
                    permission: [PermissionModul.Material_Task_Plan, Permission.Update]
                }
            },
            {
                path: 'tasktemplate/edit/:id/:idx?',
                name: 'materialtasktemplateedit',
                components: {
                    component: MaterialTables,
                    CRUD: EntityTaskTempalteEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    ...moduleMeta,
                    permission: [PermissionModul.Material_Task_Plan, Permission.Update]
                }
            },
        ],
        meta: {
            // loading: true
        }
    },
]

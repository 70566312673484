
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {AuktionDTO, DienstDTO, GenericError, OrgUnitDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import DateForm from "@/components/DateForm.vue";
import FormInput from "@/views/form/components/FormInput.vue";
import FileUpload from "@/components/FileUpload.vue";
import moment from "moment";
import {DateHelper} from "@/model/Date";
import {Module} from "@/model/Constants";
import {Checkin} from "@/model/Checkin";
import LabelComponent from "@/components/LabelComponent.vue";
import NumberForm from "@/components/NumberForm.vue";
import {Auktion, Suche} from "@/model/Diva";
import EinweisungGegenstandComponent from "@/views/einweisung/components/EinweisungGegenstandComponent.vue";
import SuchenComponent from "@/views/diva/data/SuchenComponent.vue";
import {DivaApi} from "@/services/DivaApi";

@Component({
  computed: {
    Auktion() {
      return Auktion
    },
    Module() {
      return Module
    }
  },
  components: {
    SuchenComponent,
    EinweisungGegenstandComponent,
    NumberForm,
    LabelComponent,
    FileUpload,
    FormInput,
    DateForm,
    PersonenkreisChooser,
    Multiselect
  }
})
export default class AuktionDataView extends Vue {
  @Prop() value: AuktionDTO;
  @Prop() error: GenericError;
  data: AuktionDTO = this.$props.value;

  loadingComplete = false
  personenkreisOptions = []

  dienstOpts: DienstDTO[] = []
  orgUnitOpts: OrgUnitDTO[] = []


  async created() {
    this.$root.$emit('loadingStart')
    this.dienstOpts = await this.$store.dispatch("checkin/fetchDienste")
    this.orgUnitOpts = await this.$store.dispatch("diva/fetchOrgUnits")
    this.init()
    this.$root.$emit('loadingDone')

  }

  async init() {
    this.loadingComplete = true
  }

  dienstChanged() {
    Checkin.handleStartzeit(this.data, true)
    this.datumTimeChanged(true)
  }


  datumTimeChanged(force = false) {
    if (this.data.datumStart) {
      const start = moment(this.data.zeitStart, "HH:mm");
      const ende = moment(this.data.zeitEnde, "HH:mm");

      if (start.isValid() && ende.isValid() && start.isAfter(ende)) {
        if (!this.data.datumEnde || DateHelper.getSqlDate(this.data.datumStart) == DateHelper.getSqlDate(this.data.datumEnde)) {
          this.data.datumEnde = moment(DateHelper.getSqlDate(this.data.datumStart)).add(1, "day").format("DD.MM.YYYY");
        }
      } else if (force || !this.data.datumEnde) {
        this.data.datumEnde = this.data.datumStart
      }
    }
  }

  addSuche() {
    this.data.suchen.push(new Suche())
  }

  removeSuche(idx) {
    this.data.suchen.splice(idx, 1)
  }

  showUser(suche) {
    DivaApi.getSuchenUser(suche.id).then((res) => {
      if (res.length == 0) {
        this.$alert("Mitarbeiterliste wird zusammengestellt. Versuchen Sie es in wenigen Minuten erneut.", "In Bearbeitung", 'info')
      } else {
        //this.$bvModal.show('modal-user', {data: res})
      }
    }).catch((e) => {
      if (e.raw.response.status == 404)
        this.$alert("Keine Mitarbeiter gefunden", "Warnung", 'warning')
      else {
        this.$alert("Fehler beim Laden der Mitarbeiterliste", "Fehler", 'error')
      }
    })
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
  }
}

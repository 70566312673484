import { render, staticRenderFns } from "./UserDashboardEinweisungenCard.vue?vue&type=template&id=d966bdaa&scoped=true&"
import script from "./UserDashboardEinweisungenCard.vue?vue&type=script&lang=ts&"
export * from "./UserDashboardEinweisungenCard.vue?vue&type=script&lang=ts&"
import style0 from "./UserDashboardEinweisungenCard.vue?vue&type=style&index=0&id=d966bdaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d966bdaa",
  null
  
)

export default component.exports